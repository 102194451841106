import axios from "axios";
// Función para obtener los datos de los usuarios
export const fetchUsers = async () => {
  try {
    const url = "https://indra-liquidez.vercel.app/liquidez/user/userapp";

    const headers = {
      "Content-Type": "application/json",
      Authorization: JSON.parse(localStorage.getItem("token")),
    };

    const response = await axios.get(url, { headers });

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Función para obtener los datos de las facturas de compras y ventas
export const fetchData = async (userId, selectedEndDate, selectedStartDate) => {
  const id_user = userId;

  try {
    const url =
      "https://indra-liquidez.vercel.app/liquidez/facturas/consultar/dashboard";

    const data = {
      iUserID: id_user,
      fechaDesde: selectedEndDate,
      fechaHasta: selectedStartDate,
    };

    const headers = {
      "Content-Type": "application/json",
      Authorization: JSON.parse(localStorage.getItem("token")),
    };

    const response = await axios.post(url, data, { headers });
    const EXPORT_EXCEL = response.data.message;

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// Función para formatear un número a formato de moneda
export const formatCurrency = (amount) => {
  return new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  }).format(amount);
};

// Función para capitalizar la primera letra de una cadena
export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

//crea un funcion que agreue ". y - " al rut cada 3 y 6 digitos respectivamente
export const formatRut = (rut) => {
  rut = rut.replace(/[.-]/g, "");
  rut = rut.replace(/^(\d{1,2})(\d{3})(\d{3})(\w{1})$/, "$1.$2.$3-$4");
  return rut;
};

// Función para formatear una fecha en el formato "dd-MM-yyyy"
export const formatDateToCustomFormat = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};
