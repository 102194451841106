import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import {
  TextField,
  InputAdornment,
  IconButton as MuiIconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import axios from "axios";
import { Formik, Form, Field } from "formik";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const buttonSync = {
  borderRadius: "8px",
  backgroundColor: "#0AB9CC",
  color: "#fff",
  "&:hover": {
    backgroundColor: "red",
    color: "#fff",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#F9A825",
    color: "#fff",
  },
  "&:focus": {
    boxShadow: "0 0 0 0.2rem rgba(249,168,37,.5)",
  },
};

function BootstrapDialogTitle() {
  return (
    <DialogTitle sx={{ m: 0, p: 2 }}>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/5/57/Logotipo_Servicio_de_Impuestos_Internos.svg"
          width={150}
          height={100}
          alt="Modal"
        />
        <img
          src="https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png"
          width={200}
          height="auto"
          alt="Modal"
        />
      </Box>
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const R_SOCIAL = JSON.parse(localStorage.getItem("rsocial"));

function UpdatePasswordForm({ onClose, rutValue }) {
  const [showPassword, setShowPassword] = useState("");
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const initialValues = {
    sComments: "",
  };

  // Handle the close button click
  const handleClose = () => {
    onClose();
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const ID = JSON.parse(localStorage.getItem("id"));
    const BEARER = JSON.parse(localStorage.getItem("token"));
    const RUT_USER = JSON.parse(localStorage.getItem("rut"));
    axios
      .put(
        `https://indra-liquidez.vercel.app/liquidez/pwd/pass/sii`,
        {
          //datos que no se actualizan
          sUserId: ID,
          sComments: values.sComments,
          sRUT: RUT_USER,
        },
        {
          headers: {
            Authorization: `${BEARER}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data.status);
        if (res.data.status === "fail") {
          alert("Su contraseña de sii es incorrecta, intente nuevamente");
        } else {
          alert("Contraseña actualizada correctamente");
          window.location.reload();
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <Form>
          <DialogContent dividers>
            <Typography
              gutterBottom
              sx={{
                mb: 4,
              }}
            >
              <Typography
                gutterBottom
                sx={{
                  mb: 2,
                  fontWeight: "bold",
                }}
              >
                ¡Hola {R_SOCIAL}!
              </Typography>
              Para poder actualizar la información de tus facturas, necesitamos
              que ingreses,
              <b> por única vez</b>, las
              <b> credenciales de tu empresa</b> para sincronizar la información
              de tus facturas desde el SII. No te preocupes: sólo leemos
              información de facturación, y no tocamos nada. Además, las
              contraseñas se guardan encriptadas por seguridad.
            </Typography>

            <Field
              reqquired
              as={TextField}
              size="small"
              label="Contraseña SII"
              fullWidth
              placeholder="********"
              type={showPassword ? "text" : "password"}
              name="sComments"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <MuiIconButton
                      aria-label="toggle password visibility"
                      onClick={handleTogglePasswordVisibility}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </MuiIconButton>
                  </InputAdornment>
                ),
              }}
            />
          </DialogContent>
          <DialogActions
            sx={{
              mb: 2,
              mt: 2,
            }}
          >
            <Button onClick={handleClose} color="primary">
              Cerrar
            </Button>
            <Button
              size="small"
              disableElevation
              variant="contained"
              borderRadius="8px"
              style={buttonSync}
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Enviando..." : "Actualizar información"}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
}

export default function CustomizedDialogs() {
  const [open, setOpen] = React.useState(true);

  // Function to close the modal
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <BootstrapDialog aria-labelledby="customized-dialog-title" open={open}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => setOpen(false)}
        />
        <UpdatePasswordForm onClose={handleClose} />
      </BootstrapDialog>
    </div>
  );
}
