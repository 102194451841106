import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbar,
  esES,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { useMediaQuery } from "@mui/material";

function LiquidacionesMutuosComponente() {
  const isMobile = useMediaQuery("(max-width:600px)");

  const columns = [
    {
      field: "idOperEnCurso",
      headerName: "Nº Operación",
      id: "idOperEnCurso",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "fechaOtorg",
      headerName: "Fecha de otorgamiento",
      id: "fechaOtorg",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "documento",
      headerName: "Documento",
      id: "documento",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "moneda",
      headerName: "Moneda",
      id: "moneda",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "valorCuota",
      headerName: "valorCuota",
      id: "valorCuota",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "capital",
      headerName: "Capital",
      id: "capital",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "interes",
      headerName: "Interes",
      id: "interes",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "valorCuotaLocal",
      headerName: "Valor Cuota Local",
      id: "valorCuotaLocal",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "capitalLocal",
      headerName: "Capital Local",
      id: "capitalLocal",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "interesLocal",
      headerName: "Interes Local",
      id: "interesLocal",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "vigenteContab",
      headerName: "Vigente Contab",
      id: "vigenteContab",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "devengado",
      headerName: "Devengado",
      id: "devengado",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "tasa",
      headerName: "Tasa",
      id: "tasa",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
  ];

  const [open, setOpen] = useState(false);

  const formatNumber = (number) => {
    const formato = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "CLP",
    });
    const output = formato.format(number);

    return output;
  };

  // quitar guion y digito verificador
  const quitarGuion = (rut) => {
    let rutSinGuion = rut.replace("-", "");
    let rutSinGuionYDigito = rutSinGuion.slice(0, -1);
    return rutSinGuionYDigito;
  };

  const [info, setInfo] = useState([]);

  const formatearFecha = (fecha) => {
    const opciones = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };
    const fechaFormateada = new Date(fecha).toLocaleDateString(
      "es-CL",
      opciones
    );
    return fechaFormateada;
  };

  useEffect(() => {
    setOpen(true);
    axios
      .post(
        "https://api.liquidez.cl/api/cliente/CarteraVigenteM",
        {
          rut: quitarGuion(JSON.parse(localStorage.getItem("rut"))),
          refreshToken: "refresh_token",
        },
        {
          headers: { ApiKey: process.env.REACT_APP_API_KEY_GVE },
          accept: "application/json",
          "content-type": "application/json",
        }
      )
      .then((response) => {
        const arr = response.data.map((x) => {
          return {
            ...x,
            valorCuota: formatNumber(x.valorCuota),
            capital: formatNumber(x.capital),
            interes: formatNumber(x.interes),
            valorCuotaLocal: formatNumber(x.valorCuotaLocal),
            capitalLocal: formatNumber(x.capitalLocal),
            interesLocal: formatNumber(x.interesLocal),
            devengado: formatNumber(x.devengado),
            fechaOtorg: new Date(x.fechaOtorg),
            vigenteContab: formatNumber(x.vigenteContab),
          };
        });
        arr.sort((a, b) => b.fechaOtorg - a.fechaOtorg);
        const data = arr.map((x) => {
          return {
            ...x,
            fechaOtorg: formatearFecha(x.fechaOtorg),
          };
        });
        setInfo(data);
        setOpen(false);
      })
      .catch((error) => {
        setOpen(false);
        if (error.response.status === 400) {
          Swal.fire({
            imageUrl:
              "https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png",
            title: "Lo sentimos",
            text: "No hay histórico de operaciones asociadas a este Producto.",
            confirmButtonText: "continuar",
            confirmButtonColor: "#E94E1B",
            showCloseButton: true,
          });
        }
      });
  }, []);

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 2,
          pb: 1,
        }}
        display="flex"
      >
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput) =>
            searchInput
              .split(",")
              .map((value) => value.trim())
              .filter((value) => value !== "")
          }
        />
      </Box>
    );
  }

  return (
    <div>
      <Backdrop
        sx={{
          color: "#DFFEFF",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        cargando información...
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="xl">
        <Stack className="box-componente-perfil">
          <Typography
            variant="h1"
            component="div"
            gutterBottom
            fontSize="16px"
            fontWeight="bold"
            align="left"
          >
            Liquidaciones Mutuos
          </Typography>
          <hr></hr>
          <Box sx={{ height: 750, width: "100%" }}>
            <DataGrid
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              rows={info}
              columns={columns}
              pageSize={20}
              getRowId={(row) => row.idOperEnCurso}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </Box>
        </Stack>
      </Container>
    </div>
  );
}

export default LiquidacionesMutuosComponente;
