import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  TextField,
} from "@mui/material";
import { fetchData, fetchUsers, formatDateToCustomFormat } from "./utils";
import { LoadingButton } from "@mui/lab";
import * as XLSX from "xlsx";
import GetAppIcon from "@mui/icons-material/GetApp";

const SelectUser = ({
  users,
  setUserId,
  setFacturasCompras,
  setFacturasVentas,
  setNcrCompras,
  setNcrVentas,
  setVentasPorMes,
  setComprasPorMes,
  setFechas,
  setFacturasVentasCecionadas,
}) => {
  const [selectedUserRsocial, setSelectedUserRsocial] = useState("");
  const [fetchedUsers, setFetchedUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState("");
  const [selectedEndDate, setSelectedEndDate] = useState("");
  const [appUsers, setAppUsers] = useState([]);
  const [exportData, setExportData] = useState([]); // Estado local para almacenar los datos a exportar

  const handleStartDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setDate(selectedDate.getDate() + 1); // Sumamos un día para ajustar la zona horaria
    const formattedDate = formatDateToCustomFormat(selectedDate);
    setSelectedStartDate(formattedDate);
  };

  const handleEndDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    selectedDate.setDate(selectedDate.getDate() + 1); // Sumamos un día para ajustar la zona horaria
    const formattedDate = formatDateToCustomFormat(selectedDate);
    setSelectedEndDate(formattedDate);
  };

  function exportToExcel(data, filename) {
    const wb = XLSX.utils.book_new(); // Crea un nuevo workbook

    // Añade las facturas de compras a una hoja
    XLSX.utils.book_append_sheet(
      wb,
      XLSX.utils.json_to_sheet(data.facturasCompras),
      "Facturas Compras"
    );

    // Añade las notas de crédito de compras a otra hoja
    XLSX.utils.book_append_sheet(
      wb,
      XLSX.utils.json_to_sheet(data.ncrCompras),
      "NCR Compras"
    );

    // Añade las facturas de ventas a otra hoja
    XLSX.utils.book_append_sheet(
      wb,
      XLSX.utils.json_to_sheet(data.facturasVentas),
      "Facturas Ventas"
    );

    // Añade las notas de crédito de ventas a otra hoja
    if (data.ncrVentas && data.ncrVentas.length > 0) {
      XLSX.utils.book_append_sheet(
        wb,
        XLSX.utils.json_to_sheet(data.ncrVentas),
        "NCR Ventas"
      );
    }

    // Exporta cada mes a una hoja diferente para facturasComprasMes:
    for (const month in data.facturasComprasMes) {
      XLSX.utils.book_append_sheet(
        wb,
        XLSX.utils.json_to_sheet(data.facturasComprasMes[month]),
        "Facturas Compras " + month
      );
    }

    // Exporta cada mes a una hoja diferente para ncrComprasMes:
    for (const month in data.ncrComprasMes) {
      XLSX.utils.book_append_sheet(
        wb,
        XLSX.utils.json_to_sheet(data.ncrComprasMes[month]),
        "NCR Compras " + month
      );
    }

    // Exporta cada mes a una hoja diferente para facturasVentasMes:
    for (const month in data.facturasVentasMes) {
      XLSX.utils.book_append_sheet(
        wb,
        XLSX.utils.json_to_sheet(data.facturasVentasMes[month]),
        "Facturas Ventas " + month
      );
    }

    // Exporta cada mes a una hoja diferente para ncrVentasMes (en caso de que tenga datos en el futuro):
    for (const month in data.ncrVentasMes) {
      XLSX.utils.book_append_sheet(
        wb,
        XLSX.utils.json_to_sheet(data.ncrVentasMes[month]),
        "NCR Ventas " + month
      );
    }

    // Guarda el workbook como archivo
    XLSX.writeFile(wb, filename);
  }

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const users = await fetchUsers();
        const filteredUsers = users.message
          .filter((user) => user.sComments && user.sComments !== "")
          .sort((a, b) => a.sRazonSocial.localeCompare(b.sRazonSocial));
        setFetchedUsers(filteredUsers);
        setAppUsers(users.message);
      } catch (error) {
        setError("Error fetching users.");
      } finally {
        setLoading(false);
      }
    };

    fetchAllUsers();
  }, []);

  async function handleChange() {
    const selectedId = document.getElementById("user").value;

    if (selectedId) {
      const selectedUser = appUsers.find(
        (user) => user.iUserID === Number(selectedId)
      );

      if (selectedUser) {
        const fechas =
          "Desde: " + selectedStartDate + " Hasta: " + selectedEndDate;
        setSelectedUserRsocial(selectedUser.sRazonSocial);

        const data = await fetchData(
          selectedId,
          selectedStartDate,
          selectedEndDate
        );

        // Colocamos todas las actualizaciones de estado después de fetchData
        setUserId(selectedId);
        setExportData(data);
        // facturas compras y ventas

        setFacturasCompras(data.facturasCompras);
        setFacturasVentas(data.facturasVentas);

        // notas de credito compras y ventas

        setNcrCompras(data.ncrCompras);
        setNcrVentas(data.ncrVentas);

        // facturas compras y ventas por mes

        setVentasPorMes(data.facturasVentasMes);
        setComprasPorMes(data.facturasComprasMes);

        // facturas cesionadas de ventas
        setFacturasVentasCecionadas(data.facturasVentasCecionadas);
        // fechas

        setFechas(fechas);
      }
    }
  }

  return (
    <>
      {loading ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h1"
            component="div"
            gutterBottom
            fontSize="16px"
            fontWeight="bold"
            align="left"
            color={"#1A2027"}
          >
            Cargando usuarios...
          </Typography>
          <CircularProgress sx={{ ml: 2 }} />
        </Box>
      ) : (
        <>
          {error && <p>{error}</p>}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              gap: "10px",
            }}
          >
            {" "}
            <TextField
              required // Nuevo atributo
              size="small"
              type="date"
              onChange={handleStartDateChange}
            />
            <TextField
              required // Nuevo atributo
              size="small"
              type="date"
              onChange={handleEndDateChange}
            />
            <select
              style={{
                width: "300px",
                height: "40px",
                borderRadius: "5px",
              }}
              required // Nuevo atributo
              name="user"
              id="user"
              className="form-control"
            >
              <option value="">Seleccione un usuario</option>
              {fetchedUsers.map((user) => (
                <option key={user.iUserID} value={user.iUserID}>
                  {user.sRazonSocial}
                </option>
              ))}
            </select>
            <LoadingButton
              className="btn-buscar"
              size="small"
              variant="contained"
              onClick={() => {
                handleChange();
              }}
            >
              Consultar
            </LoadingButton>
            <Button
              size="small"
              variant="contained"
              style={{
                backgroundColor: "#217346", // Un verde similar al de Excel
                color: "white", // Texto blanco para mejor contraste
              }}
              onClick={() => {
                if (selectedUserRsocial) {
                  // Convertir la razón social en un nombre de archivo seguro (removiendo caracteres no deseados)
                  const filename =
                    selectedUserRsocial.replace(/[^a-zA-Z0-9]/g, "_") + ".xlsx";
                  exportToExcel(exportData, filename);
                } else {
                  alert("Seleccione un usuario primero.");
                }
              }}
            >
              Exportar a Excel la data consultada
              <GetAppIcon style={{ marginLeft: "8px" }} />
            </Button>
          </Box>
        </>
      )}
    </>
  );
};

export default SelectUser;
