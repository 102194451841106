import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import LockIcon from "@mui/icons-material/Lock";
import { LoadingButton } from "@mui/lab";
import swal from "sweetalert2";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import EmailIcon from "@mui/icons-material/Email";
import Container from "@mui/material/Container";

const Login = () => {
  const [body, setBody] = useState({ login: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [loginerror, setLoginError] = useState(false);
  const [passworderror, setPasswordError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const isFormComplete = () =>
    body.login.length > 0 && body.password.length > 0;

  /*  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  
 */

  const handleChange = (e) => {
    setLoginError(false);
    setPasswordError(false);

    if (e.target.name === "login") {
      setLoginError(true);
    }
    if (e.target.name === "password") {
      setPasswordError(true);
    }
    setBody({
      ...body,
      [e.target.name]: e.target.value,
    });
  };

  async function entrar() {
    function isEmpty() {
      if (body.login === "" || body.password === "") {
        swal.fire({
          toast: true,
          position: "top-end",
          text: "Los campos no pueden estar vacios",
          icon: "info",
          showConfirmButton: false,
          timer: 1000,
        });
        return true;
      }
      return false;
    }
    if (isEmpty()) {
      return;
    }
    setLoading(true);
    let items = { body };
    axios
      .post(`${process.env.REACT_APP_HOST_API}liquidez/user/login`, {
        login: body.login,
        password: body.password,
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          /* localStorage.setItem("auth", JSON.stringify("yes")); */
          //parseado
          localStorage.setItem("auth", JSON.stringify("yes"));
          localStorage.setItem(
            "rut",
            JSON.stringify(response.data.message.rut)
          );
          localStorage.setItem(
            "nombre",
            JSON.stringify(response.data.message.first_name)
          );
          localStorage.setItem(
            "apellido",
            JSON.stringify(response.data.message.last_name)
          );
          localStorage.setItem(
            "token",
            JSON.stringify(response.data.message.Authorization)
          );
          localStorage.setItem(
            "id",
            JSON.stringify(response.data.message.userId)
          );
          localStorage.setItem(
            "profile",
            JSON.stringify(response.data.message.data.profile)
          );
          localStorage.setItem(
            "rsocial",
            JSON.stringify(response.data.message.data.razonSocial)
          );
          // eslint-disable-next-line no-restricted-globals
          setOpen(true);
          // eslint-disable-next-line no-restricted-globals
          setTimeout(function () {
            window.location.href = "/";
          }, 2500);
          swal.fire({
            toast: true,
            title: "Bienvenido a Liquidez, pronto serás redireccionado",
            timerProgressBar: true,
            icon: "success",
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
          });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        swal.fire({
          toast: true,
          title: "Usuario o contraseña incorrectos",
          timerProgressBar: true,
          icon: "error",
          position: "top-end",
          showConfirmButton: false,
          timer: 1000,
        });
        setLoading(false);
      });
  }

  const theme = createTheme();

  return (
    <>
      <ThemeProvider theme={theme}>
        <Backdrop
          sx={{
            color: "#DFFEFF",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
        >
          Bienvenido a Liquidez...
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container sx={{ height: "100vh" }}>
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage:
                "url(https://indrasolutions.cl/wp-content/uploads/2022/09/login-1-1-min.webp)",
              backgroundRepeat: "cover",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />

          <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <img
                class="logo-login"
                src="https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png"
                alt="i"
              ></img>
              <Typography
                className="texto-paginas-login"
                component="h1"
                variant="h5"
                sx={{ mt: 4 }}
              >
                Ingresa tus datos para iniciar sesión
              </Typography>

              <Box component="form" sx={{ mt: 4 }}>
                <TextField
                  onChange={handleChange}
                  fullWidth
                  size="large"
                  color="primary"
                  margin="normal"
                  variant="outlined"
                  label="Correo electrónico"
                  placeholder="ingrese su correo"
                  name="login"
                  type="email"
                  value={body.login}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        onChange={handleChange}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        className="iconos-labels"
                        position="start"
                      >
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                />

                <br></br>
                <TextField
                  onChange={handleChange}
                  fullWidth
                  size="large"
                  type="password"
                  color="primary"
                  margin="normal"
                  variant="outlined"
                  label="Contraseña"
                  placeholder="ingrese su contraseña"
                  name="password"
                  value={body.password}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        className="iconos-labels"
                        position="start"
                      >
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        onChange={handleChange}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />

                <br></br>

                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label="Recuérdame"
                />

                <Link className="texto-1" to="/recuperar" variant="body2">
                  ¿Has olvidado tu contraseña?
                </Link>

                <LoadingButton
                  disableElevation
                  type="submit"
                  disabled={body.login === "" || body.password === ""}
                  size="large"
                  className={isFormComplete() ? "enabled" : "disabled"}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  loading={loading}
                  loadingPosition="end"
                  onClick={() => {
                    entrar();
                  }}
                >
                  Ingresar
                </LoadingButton>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                  sx={{ mt: 4 }}
                  fontSize="16px"
                >
                  ¿Nuevo en nuestra plataforma?
                  <Link
                    style={{
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                    to="/registro"
                    className="texto-1"
                  >
                    {" "}
                    Crear una cuenta{" "}
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};
export default Login;
