import React, { useState } from "react";
import "./CompanyLogin.css";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import axios from "axios";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Swal from "sweetalert2";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

function CompanyLogin() {
  // Crea un estado para almacenar los valores de los campos del formulario
  const [formData, setFormData] = useState({
    empresa_rut: "",
    empresa_pwd: "",
  });

  const rut = JSON.parse(localStorage.getItem("rut"));
  //TOKEN BEARER SII
  const TOKENSII_SII = localStorage.getItem("tokenSII");
  const [open, setOpen] = useState(false);

  const handleSubmit = async (e) => {
    if (formData.empresa_pwd === "") {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "info",
        title: "Debe ingresar su contraseña",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
      return;
    }
    setOpen(true);

    e.preventDefault();

    // Codifica la contraseña en base64 y agrega el símbolo @ al principio
    const formattedPassword = btoa("@" + formData.empresa_pwd);

    try {
      const response = await axios
        .post(
          "https://api-sii.webfactura.cl/api/user/assocEmp",
          {
            empresa_rut: rut,
            empresa_pwd: formattedPassword,
          },
          {
            headers: {
              Authorization: `Bearer ${TOKENSII_SII}`,
            },
          }
        )
        .then((response) => {
          setOpen(true);
          if (
            response.data.message ===
            "Modificando Informacion empresa registrada"
          ) {
            Swal.fire({
              imageUrl:
                "https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png",
              text: "Hemos enviado sus datos a su cuenta de Liquidez exitosamente",
              showConfirmButton: true,
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#143785",
            });
            setOpen(false);
          } else if (response.data.message === "Creado Exitosamente") {
            Swal.fire({
              imageUrl:
                "https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png",
              text: "Hemos enviado sus datos a su cuenta de Liquidez exitosamente",
              showConfirmButton: true,
              confirmButtonText: "Aceptar",
              confirmButtonColor: "#143785",
            });
            setOpen(false);
          }
        });
    } catch (error) {
      setOpen(false);
      Swal.fire({
        imageUrl:
          "https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png",
        text: "Estimado usuario, ha estado inactivo por un tiempo, por favor vuelva a iniciar sesión.",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
        confirmButtonColor: "#143785",
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.clear();
          window.location.href = "/";
        }
      });
    }
  };

  // Función manejadora de cambio de los campos del formulario
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Backdrop
        sx={{
          color: "#DFFEFF",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        Sincronizando...
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={6} md={4}>
            <Stack className="box-componente-perfil">
              <Typography
                variant="h1"
                component="div"
                gutterBottom
                fontSize="16px"
                fontWeight="bold"
                align="left"
              >
                Enviar datos SII a Liquidez
              </Typography>
              <hr />
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
                justifyContent="space-evenly"
                alignItems="center"
              >
                <form onSubmit={handleSubmit}>
                  <Typography>
                    <Alert
                      sx={{ mb: 2 }}
                      fontSize="16px"
                      severity="info"
                      align="left"
                      style={{
                        fontWeight: "500",
                      }}
                    >
                      Sincroniza tu clave de SII, accederás a información de tus
                      facturas en línea y podrás cederlas rápida y fácilmente a
                      través de nuestra plataforma.
                    </Alert>
                  </Typography>
                  <TextField
                    sx={{
                      opacity: 0.5,
                      bgcolor: "",
                    }}
                    size="small"
                    fullWidth
                    disabled
                    value={rut}
                    type="text"
                    name="empresa_rut"
                    onChange={handleChange}
                  />
                  <br></br>
                  <br></br>
                  <TextField
                    size="small"
                    fullWidth
                    label="Contraseña SII"
                    placeholder="Introduzca su contraseña de SII"
                    type="password"
                    name="empresa_pwd"
                    onChange={handleChange}
                  />

                  <br></br>
                  <br></br>
                  <Button
                    onClick={handleSubmit}
                    className="btn-asociar"
                    variant="contained"
                    size="small"
                    fullWidth
                  >
                    Enviar
                  </Button>
                </form>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6} md={8}>
            <Stack className="box-componente-perfil">
              <Typography
                variant="h1"
                component="div"
                gutterBottom
                fontSize="16px"
                fontWeight="bold"
                align="left"
              >
                ¿Por qué enviar esta información?
                <hr></hr>
              </Typography>
              <Alert
                sx={{ mb: 2 }}
                fontSize="16px"
                severity="info"
                align="left"
                style={{
                  fontWeight: "500",
                }}
              >
                La Sincronización con el SII le permite a Liquidez Capital
                solamente tener acceso a la facturación, no permite emisión de
                facturas, notas de crédito y menos aún transacciones de dinero
                de ningún tipo.
              </Alert>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default CompanyLogin;
