import React from 'react';
import ListItem from '@mui/material/ListItem';

const LogoSidebar = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <ListItem>
        <img
          src="https://indrasolutions.cl/wp-content/uploads/2022/08/Logo-1-1.png"
          alt="logo"
          width="100%"
        />
      </ListItem>
    </div>
  );
};

export default LogoSidebar;
