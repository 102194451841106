import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import RecuperarContraseña from './pages/RecuperarContraseña'; 
import Inicio from './pages/Inicio';
import PaginaError from './pages/PaginaError';
import PaginaRegistro from './pages/PaginaRegistro';
import RouteController from './componentes/routecontroller/RouteController';
import PerfilUsuarioPage from './pages/dashboardpages/PerfilUsuarioPage';
import InicioDashboardPage from './pages/dashboardpages/InicioDashboardPage';
import ResumenLiquidacionPage from './pages/dashboardpages/ResumenLiquidacionPage';
import OfertasPage from './pages/dashboardpages/OfertasPage';
import CarteraVigentePage from './pages/dashboardpages/CarteraVigentePage';
import LiquidacionesMutuosPage from './pages/dashboardpages/LiquidacionesMutuosPage';
import CarteraVigenteMutuosPage from './pages/dashboardpages/CarteraVigenteMutuosPage';
import DetalleDeOperacionPage from './pages/dashboardpages/DetalleDeOperacionPage';
import SincronizacionesPage from './pages/dashboardpages/SincronizacionesPage';
import UploadCertPage from './pages/dashboardpages/UploadCertPage';
import FacturasPendientesPage from './pages/dashboardpages/FacturasPendientesPage';
import FacturasParaCederPage from './pages/dashboardpages/FacturasParaCederPage';
import ConsultarStatusClientesPage from './pages/dashboardpages/ConsultarStatusClientesPage';
import AnalisisDeEmpresasPage from './pages/dashboardpages/AnalisisDeEmpresasPage';
import FacturasFiveDaysPage from './pages/dashboardpages/FacturasFiveDaysPage';



function App() {

  return (
    <div className="App">

    {/* rutas publicas  */}
    <BrowserRouter>
       <Routes>
            <Route path="/login" element={<Inicio/>} /> 
            <Route path="/recuperar" element={<RecuperarContraseña/>}/>
            <Route path="/registro" element={<PaginaRegistro/>}/>
            <Route path="*" element={<PaginaError/>}/> 
        </Routes> 
    </BrowserRouter>  

    {/* rutas privadas  */}
        <BrowserRouter>
        <Routes>
          <Route exact path="facturas-pendientes" element={<RouteController component={FacturasPendientesPage} />} />
          <Route exact path="facturas-5-dias" element={<RouteController component={FacturasFiveDaysPage} />} />
          <Route exact path="historico-de-estatus-facturas" element={<RouteController component={ConsultarStatusClientesPage} />} />
          <Route exact path="analisis-de-empresa" element={<RouteController component={AnalisisDeEmpresasPage}/>}/>
            <Route exact path="sincronizar" element={<RouteController component={SincronizacionesPage}/>}/>
            <Route exact path="ver-facturas" element={<RouteController component={OfertasPage}/>}/>
            <Route exact path="subir-certificado" element={<RouteController component={UploadCertPage}/>}/>
            <Route exact path="liquidaciones-factoring" element={<RouteController component={ResumenLiquidacionPage}/>}/> 
            <Route exact path="liquidaciones-mutuos" element={<RouteController component={LiquidacionesMutuosPage}/>}/> 
            <Route exact path="cartera-vigente-factoring" element={<RouteController component={CarteraVigentePage}/>}/>
            <Route exact path="cartera-vigente-mutuos" element={<RouteController component={CarteraVigenteMutuosPage}/>}/>
            <Route exact path="detalle-operacion" element={<RouteController component={PerfilUsuarioPage}/>}/>
            <Route exact path="/" element={<RouteController component={InicioDashboardPage}/>}/>
            <Route exact path="perfil-usuario" element={<RouteController component={PerfilUsuarioPage}/>}/>
            <Route exact path="detalle-de-operacion" element={<RouteController component={DetalleDeOperacionPage}/>}/>
            <Route exact path="facturas-para-ceder" element={<RouteController component={FacturasParaCederPage}/>}/>
        </Routes>
        </BrowserRouter>
    </div>

  );
}
export default App;


