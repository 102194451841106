import React, { useEffect, useState, useRef } from "react";
import "./OfertasComponente.css";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import { FormControl, MenuItem, Select } from "@mui/material";
import axios from "axios";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import { renderToString } from "react-dom/server";
import MailAnticipo from "../../mails/MailAnticipo";
import FormularioCesion from "../../SIIComponentes/FormularioCesion";
import { useMediaQuery } from "@mui/material";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import { Alert } from "@mui/material";
import ModalSendSIIAdmin from "../../modals/ModalSendSIIAdmin";

function OfertasComponente({ rows }) {
  const apiRef = useRef(null);

  const isMobile = useMediaQuery("(max-width:600px)");

  const ID = JSON.parse(localStorage.getItem("id"));
  const BEARER = JSON.parse(localStorage.getItem("token"));
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [infoCesion, setInfoCesion] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  //funcion para ver las cesiones disponibles
  async function verCesiones() {
    const data = await axios.get(
      `${process.env.REACT_APP_HOST_API}liquidez/facturas/cedidas?iUserID=${ID}`,
      {
        headers: {
          Authorization: `${BEARER}`,
        },
      }
    );
    setInfoCesion(data.data.message);
  }

  const [infodata, setInfodata] = useState([]);

  //funcion para ver las facturas aprobadas
  const [facturasAprobadas, setFacturasAprobadas] = useState([]);
  const URL_FACTURA_CEDIBLE = `${process.env.REACT_APP_HOST_API}liquidez/facturas/cedidas/`;

  function handleCedibles() {
    axios
      .get(`${URL_FACTURA_CEDIBLE}${ID}`, {
        headers: {
          Authorization: `${BEARER}`,
        },
      })
      .then((res) => {
        const arr = res.data.message.map((x) => {
          return x.iNumDoc;
        });

        setInfodata(arr);
        setFacturas(res.data.message);
        setFacturasAprobadas(res.data.message);
      })
      .catch((err) => {});
  }

  // funcion para habilitar las el boton de "ceder factura"
  const fn = (value) => {
    const cedido = infoCesion.find((x) => x.iNumDoc == value.trim());
    if (cedido && cedido.status !== "ACCEPTED") return false;
    return true;
  };

  const [open3, setOpen3] = useState(false);

  const [buttonParams, setButtonParams] = useState(null);

  function handleLogout() {
    localStorage.clear();
    window.location.href = "/login";
  }

  const RUT_USUARIO = JSON.parse(localStorage.getItem("rut"));

  //traer info del usuario
  const [usuario, setUsuario] = useState([]);
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_HOST_API}liquidez/user/userapp/${ID}`, {
        headers: {
          Authorization: `${BEARER}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          //guarda en el local storage el correo y el telefono del usuario
          localStorage.setItem("email", res.data.message["0"].sEmail);
          localStorage.setItem("phone", res.data.message["0"].sPhone);
          setUsuario(res.data);
        }
        setUsuario(res.data);
      })
      .catch((err) => {});
  }, []);

  const columns = [
    {
      field: "razon_social",
      headerName: "Deudor",
      id: "razon_social",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "rut_cliente",
      headerName: "Rut Deudor",
      id: "rut_cliente",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "folio",
      headerName: "Num Documento",
      id: "folio",
      width: 200,
    },
    {
      field: "monto_total",
      headerName: "Valor Documento",
      id: "monto_total",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "fecha_emision",
      headerName: "Fecha Emisión",
      id: "fecha_emision",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      action: "action",
      headerName: "Cotizar Factura",
      id: "action",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
      renderCell: (params) => {
        return (
          <Button
            size="small"
            disabled={
              //si la factura es igual a ACCEPTED, REVIEW se bloquea el boton de acticipar
              infoCesion.find((x) => x.iNumDoc == params.row.folio.trim())
                ?.status === "ACCEPTED" ||
              infoCesion.find((x) => x.iNumDoc == params.row.folio.trim())
                ?.status === "REVIEW" ||
              infoCesion.find((x) => x.iNumDoc == params.row.folio.trim())
                ?.status === "REJECTED" ||
              infoCesion.find((x) => x.iNumDoc == params.row.folio.trim())
                ?.status === "CESSION_APROVED"
            }
            variant="contained"
            color="primary"
            onClick={() => {
              // popup de confirmacion con los datos de la factura
              Swal.fire({
                imageUrl:
                  "https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png",
                text: "Desea cotizar la factura n° " + params.row.folio + " ?",
                showCancelButton: true,
                confirmButtonColor: "#143785",
                cancelButtonColor: "#e94e1b",
                cancelButtonText: "Cancelar",
                confirmButtonText: "¡Sí, cotizar!",
              }).then((result) => {
                if (result.isConfirmed) {
                  setOpen2(true);
                  //enviar email
                  axios
                    .post(
                      "https://api.sendinblue.com/v3/smtp/email",
                      {
                        sender: {
                          name: `Anticipo de factura - rut:  ${RUT_USUARIO}`,
                          email: "no-responder@indrasolutions.cl",
                        },
                        to: [
                          {
                            email: "esanchezabaroa@gmail.com",
                            name: "Enrique Sanchez",
                          },
                          {
                            email: "cesaralexander@indrasolutions.cl",
                            name: "Cesar Alexander",
                          },
                        ],
                        // agregar el rut del usuario que solicita el anticipo de factura en el asunto
                        subject: `Solicitud aticipo de factura - rut:  ${RUT_USUARIO}`,
                        htmlContent:
                          // agregar componente
                          renderToString(<MailAnticipo params={params} />),
                      },
                      {
                        headers: {
                          "api-key": process.env.REACT_APP_API_KEY_SENDINBLUE,
                          accept: "application/json",
                          "content-type": "application/json",
                        },
                      }
                    )
                    .then((res) => {
                      if (result.isConfirmed) {
                        Swal.fire({
                          imageUrl:
                            "https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png",
                          text: "La copia de la factura ha sido enviada a Liquidez Capital Chile, y un ejecutivo comercial se comunicará contigo a la brevedad para entregar una cotización",
                          confirmButtonColor: "#143785",
                          confirmButtonText: "Continuar",
                        });
                        setOpen2(false);
                      }
                    })
                    .catch((err) => {});
                }
              });
            }}
          >
            COTIZAR
          </Button>
        );
      },
    },
    {
      field: "status",
      headerName: "Estado",
      id: "status",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
      renderCell: (params) => {
        return (
          <div>
            {facturasAprobadas.find(
              (factura) => factura.iNumDoc == params.row.folio
            )?.status === "ACCEPTED" ? (
              <Chip color="success" size="small" label="Cedida" />
            ) : facturasAprobadas.find(
                (factura) => factura.iNumDoc == params.row.folio
              )?.status === "REJECTED" ? (
              <Chip color="error" size="small" label="Rechazada" />
            ) : facturasAprobadas.find(
                (factura) => factura.iNumDoc == params.row.folio
              )?.status === "REVIEW" ? (
              <Chip
                variant="outlined"
                color="info"
                size="small"
                label="En revisión"
              />
            ) : facturasAprobadas.find(
                (factura) => factura.iNumDoc == params.row.folio
              )?.status === "CESSION_APROVED" ? (
              <Chip
                color="warning"
                size="small"
                label="Disponible para ceder"
              />
            ) : (
              <Chip size="small" label="Por cotizar" />
            )}
          </div>
        );
      },
    },
  ];

  const rut = JSON.parse(localStorage.getItem("rut"));
  const [info, setInfo] = useState([]);
  const [operation, setOperation] = useState("");
  const [date_init, setDate_init] = useState("");
  const [date_end, setDate_end] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [anticipar, setAnticipar] = useState(false);

  const handleAnticipar = () => {
    setAnticipar(true);
  };
  const onSelectionChange = (event) => {
    setSelectedRows(event.selectedRows);
  };

  const formatNumber = (number) => {
    const formato = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "CLP",
    });
    const output = formato.format(number);
    return output;
  };

  const handleChange = (event) => {
    setOperation(event.target.value);
  };

  const onChangeDateInit = (date, dateString) => {
    setDate_init(dateString);
  };

  const onChangeDateEnd = (date, dateString) => {
    setDate_end(dateString);
  };

  //fechas formateadas
  var moment = require("moment");
  var now = moment().format("DD/MM/YYYY");

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  // Obtenemos el valor numérico de día, mes y año de la fecha 'date'
  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Devolvemos la fecha formateada en formato 'DD-MM-YYYY'
    return `${day < 10 ? "0" + day : day}-${
      month < 10 ? "0" + month : month
    }-${year}`;
  };

  const [total, setTotal] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const date_init = new Date(data.date_init);
    date_init.setDate(date_init.getDate() + 1);
    const date_init_formatted = formatDate(date_init);

    const date_end = new Date(data.date_end);
    date_end.setDate(date_end.getDate() + 1);
    const date_end_formatted = formatDate(date_end);

    setOpen(true);
    setOpenModal(false);
    axios
      .post(
        "https://indra-liquidez.vercel.app/liquidez/facturas/documentos",
        {
          rut: rut,
          tipoOperacion: "VENTA",
          fechaDesde: date_init_formatted,
          fechaHasta: date_end_formatted,
          tipoDocumento: "33",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: BEARER,
          },
        }
      )
      .then((response) => {
        if (
          response.data ===
          "Error al autenticar al usuario en el SII con RUT y clave"
        ) {
          setOpenModal(true);
          setOpen(false);
        } else if (
          response.data.error ===
          "Ocurrió un error consultando los documentos. La clave de SII no se encuentra registrado en el sistema"
        ) {
          setOpenModal(true);
          setOpen(false);
        } else if (
          // un array vacio
          response.data.length === 0
        ) {
          alert("No hay facturas para el rango de fechas seleccionado");
          setOpen(false);
        } else {
          setInfo(
            response.data.sort(
              (a, b) => new Date(b.fecha_emision) - new Date(a.fecha_emision)
            )
          );
          // formato de moneda
          response.data.forEach((item) => {
            item.monto_total = formatNumber(item.monto_total);
          });
          setOpen(false);
        }
      })
      .catch((error) => {
        alert(error);
        setOpen(false);
      });
  };

  const [facturas, setFacturas] = useState([]);

  return (
    <>
      {openModal && <ModalSendSIIAdmin />}
      <Backdrop
        sx={{
          color: "#DFFEFF",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open2}
      >
        Enviando Correo...
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        sx={{
          color: "#DFFEFF",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        cargando información...
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid>
        <Container maxWidth="xl">
          <Grid>
            <Stack spacing={2} className="box-componente-perfil">
              <Typography
                mb={1}
                fontSize="16px"
                variant="h3"
                fontFamily={"Roboto"}
                align="left"
                fontWeight={600}
              >
                Nueva Búsqueda
              </Typography>
              <Alert
                severity="info"
                style={{ fontSize: "15px", fontWeight: "500" }}
              >
                Seleccione el rango de fecha y el tipo de factura emitida para
                visualizar las ofertas de factoring.
              </Alert>
              <hr />
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2} columns={16}>
                  <Grid xs={8}>
                    <Item elevation={0}>
                      <TextField
                        className="inpt-emp_rut-disabled"
                        name="emp_rut"
                        label="Rut"
                        variant="outlined"
                        size="small"
                        value={rut}
                        disabled
                        fullWidth
                      />
                    </Item>
                    <Item elevation={0}>
                      <Typography
                        mb={1}
                        fontSize="16px"
                        variant="h3"
                        fontFamily={"Roboto"}
                        align="left"
                        fontWeight={600}
                        color="black"
                      >
                        Tipo de operación
                      </Typography>
                      <FormControl
                        fullWidth
                        size="small"
                        variant="outlined"
                        required
                      >
                        <Select
                          {...register("operation_type")}
                          defaultValue="VENTA"
                        >
                          <MenuItem value="VENTA">VENTA</MenuItem>
                        </Select>
                      </FormControl>
                    </Item>
                    <Item elevation={0}>
                      <Typography
                        mb={1}
                        fontSize="16px"
                        variant="h3"
                        fontFamily={"Roboto"}
                        align="left"
                        fontWeight={600}
                        color="black"
                      >
                        Fecha desde
                      </Typography>
                      <TextField
                        type="date"
                        {...register("date_init", { required: true })}
                        name="date_init"
                        size="small"
                        style={{ width: "100%" }}
                        error={!!errors.date_end}
                        helperText={
                          errors.date_end && "Este campo es requerido."
                        }
                        InputProps={{
                          style: {
                            color: errors.date_end ? "red" : "inherit",
                          },
                        }}
                      />
                    </Item>
                    <Item elevation={0}>
                      <Typography
                        mb={1}
                        fontSize="16px"
                        variant="h3"
                        fontFamily={"Roboto"}
                        align="left"
                        fontWeight={600}
                        color="black"
                      >
                        Fecha hasta
                      </Typography>
                      <TextField
                        required
                        type="date"
                        {...register("date_end", { required: true })}
                        name="date_end"
                        size="small"
                        style={{ width: "100%" }}
                        error={!!errors.date_init}
                        helperText={
                          errors.date_init && "Este campo es requerido."
                        }
                        InputProps={{
                          style: {
                            color: errors.date_init ? "red" : "inherit",
                          },
                        }}
                      />
                    </Item>
                    <Item elevation={0}>
                      <LoadingButton
                        fullWidth
                        className="btn-buscar"
                        variant="contained"
                        size="small"
                        loadingPosition="start"
                        startIcon={<SearchIcon />}
                        onClick={(event) => {
                          handleSubmit(onSubmit)(event);
                          handleCedibles();
                          verCesiones();
                        }}
                      >
                        Buscar
                      </LoadingButton>
                    </Item>
                  </Grid>
                  <Grid xs={8}>
                    <Item elevation={0}>
                      <Typography
                        variant="h1"
                        component="div"
                        gutterBottom
                        fontSize="1.5rem"
                        fontWeight="bold"
                        align="center"
                        color="black"
                      ></Typography>
                      <Typography
                        variant="h4"
                        component="div"
                        gutterBottom
                        fontSize={25}
                        fontWeight="bold"
                        align="center"
                        color="black"
                      >
                        <Typography
                          mb={1}
                          fontSize="16px"
                          variant="h3"
                          fontFamily={"Roboto"}
                          fontWeight={600}
                          color="black"
                        >
                          Total: {formatNumber(total)}
                        </Typography>
                      </Typography>
                      <Typography
                        variant="p"
                        component="div"
                        gutterBottom
                        fontStyle="italic"
                      >
                        *haga clic en las columnas para visualizar el total que
                        quiere anticipar.
                      </Typography>
                    </Item>
                    <Item elevation={0}></Item>
                  </Grid>
                </Grid>
              </form>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              ></Grid>
            </Stack>
          </Grid>
        </Container>
        <Container maxWidth="xl">
          <Grid
            className="box-componente-perfil"
            sx={{ height: 750, width: "100%" }}
          >
            <DataGrid
              align="center"
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              rows={info}
              columns={columns}
              getRowId={(row) => row.monto_total + row.folio}
              sortModel={[{ field: "monto_total", sort: "asc" }]}
              checkboxSelection
              onSelectionModelChange={(monto) => {
                const sum = monto.map((x) => {
                  return parseInt(
                    x.replaceAll(".", "").replaceAll("CLP", "").trim()
                  );
                });
                const sumatoria = sum.reduce((acc, cur) => {
                  return acc + cur;
                }, 0);
                setTotal(sumatoria);
              }}
              disableSelectionOnClick
              rowHeight={38}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </Grid>
        </Container>
        <FormularioCesion
          buttonParams={buttonParams}
          open={open3}
          handleFormClose={() => setOpen3(false)}
        />
      </Grid>
    </>
  );
}

export default OfertasComponente;
