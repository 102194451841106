import React from "react";
import { Typography, Divider, Box } from "@mui/material";
import { formatCurrency, capitalize } from "./utils";
import Skeleton from "@mui/lab/Skeleton";
import NotDataAlert from "./NotDataAlert";

const NotaCreditoCompra = ({ notaCreditoCompra, isLoading }) => {
  if (isLoading) {
    return (
      <Box>
        <Skeleton variant="text" width="60%" height={30} />
        <Divider sx={{ mt: 2, mb: 2 }} />
        {[...Array(15)].map((_, index) => (
          <Box
            key={index}
            sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
          >
            <Skeleton variant="text" width="40%" />
            <Skeleton variant="text" width="40%" />
          </Box>
        ))}
      </Box>
    );
  }

  const ncrFiltradas = notaCreditoCompra.filter(
    (ncr) => ncr.razon_social !== "Total General"
  );

  const ncrOrdenadas = [...ncrFiltradas]
    .sort((a, b) => b.monto_total - a.monto_total)
    .slice(0, 15);

  const totalMonto = ncrOrdenadas.reduce(
    (total, nota) => total + nota.monto_total,
    0
  );

  if (ncrOrdenadas.length === 0) {
    // If there are no nota crédito compra, show the NotDataAlert component.
    return (
      <NotDataAlert message="No hay notas de crédito compra para mostrar." />
    );
  }

  const renderedNotaCreditoCompra = ncrOrdenadas.map((nota, index) => (
    <Typography
      key={index}
      sx={{
        fontSize: 12,
        color: "#1A2027",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <span>{capitalize(nota.razon_social)}</span>
      <span>{formatCurrency(nota.monto_total)}</span>
    </Typography>
  ));

  return (
    <Box>
      <Typography
        variant="h1"
        component="div"
        gutterBottom
        fontSize="16px"
        fontWeight="bold"
        align="left"
        color={"#1A2027"}
      >
        Top 10 Nota Crédito Compra:
      </Typography>
      <Divider
        sx={{
          backgroundColor: "#1A2027",
          mt: 2,
          mb: 2,
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "bold",
            color: "#1A2027",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Deudor:
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "bold",
            color: "#1A2027",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Monto Bruto:
        </Typography>
      </Box>
      <Divider
        sx={{
          backgroundColor: "#1A2027",
          mt: 1,
          mb: 1,
        }}
      />
      {renderedNotaCreditoCompra}
      <Divider
        sx={{
          backgroundColor: "#1A2027",
          mt: 1,
          mb: 1,
        }}
      />
      <h1
        style={{
          fontSize: "12px",
          fontWeight: "bold",
          color: "#1A2027",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        Total
        <span>{formatCurrency(totalMonto)}</span>
      </h1>
    </Box>
  );
};

export default NotaCreditoCompra;
