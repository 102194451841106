import React from "react";

const MailRegister = ({ values }) => {

  return (
    <>
<html>
          <table>
          <tr>
            <td colspan="2" style={{ padding: '35px', paddingTop: '10px', paddingBottom: '20px' }}>
              <div style={{ margin: 0, lineHeight: '25px', background: '#fff', borderRadius: '3px', textAlign: 'left' }}>
                <img
                  src="https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png"
                  width="250"
                  alt="logo"
                />
                <h1 style={{ padding: '20px 30px', display: 'block', borderBottom: '2px solid #fff8e8' }}>
                  Nuevo usuario registrado en la intranet: {values.sFirstName} {values.sLastName}
                </h1>
                <div style={{ padding: '20px 30px', display: 'block' }}>
                  <div style={{ display: 'inline-block', lineHeight: '22px' }}>
                <strong>Nombre:</strong> {values.sFirstName}<br />
                <strong>Apellido</strong> {values.sLastName}<br />
                <strong>Email</strong> {values.sEmail}<br />
                <strong>Teléfono</strong> {values.sPhone}<br />
                <strong>Contraseña</strong> {values.sPassword}<br />
                <strong>RUT empresa</strong> {values.sRUT}<br />
                <strong>Clave SII:</strong> {values.sComments}<br />

                  </div>
                </div>
                <div style={{ padding: '20px 30px', display: 'block', fontSize: '14px', color: 'black', lineHeight: '22px' }}>
                  Saludos,
                  <br />
                  Liquidez Capital.
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="2" style={{ padding: '0 35px 20px', fontSize: '12px', color: '#c5b99d', lineHeight: '17px' }} align="center">
              El material de este correo electrónico puede ser confidencial, privilegiado y/o protegido por derechos de autor.<br />El uso de este correo electrónico debe limitarse en consecuencia. Si este correo electrónico le ha sido enviado por error, contáctenos de inmediato.
            </td>
          </tr>
        </table>        
        </html>`
    </>
  );
};

export default MailRegister;
