import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ModalRecuperarContraseña from "../modals/ModalRecuperarContraseña";
import InputAdornment from "@mui/material/InputAdornment";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import axios from "axios";

const theme = createTheme();

const RecuperarClave = () => {
  const [login, setLogin] = useState({ login: "" });
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleRecoveryPassword = (e) => {
    setLogin({
      ...login,
      [e.target.name]: e.target.value,
    });
  };
  async function enviarCorreo() {
    function isEmpty() {
      if (login.login === "") {
        Swal.fire({
          toast: true,
          position: "top-end",
          text: "El campo no puede estar vacio",
          icon: "info",
          showConfirmButton: false,
          timer: 1500,
        });
        return true;
      }
      return false;
    }
    if (isEmpty()) {
      return;
    }

    if (!/\S+@\S+\.\S+/.test(login.login)) {
      Swal.fire({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        icon: "error",
        text: "Ingrese un correo electronico valido",
      });
      return;
    }

    setLoading(true);
    let items = { login };
    console.warn(items);
    axios
      .post(`${process.env.REACT_APP_HOST_API}liquidez/pwd/reset`, {
        login: login.login,
      })
      .then((response) => {
        console.warn(response.data.status);
        setLoading(false);

        if (response.data.status === "success") {
          Swal.fire({
            toast: true,
            position: "top-end",
            text: "Correo enviado",
            icon: "success",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar:true
          });
          setModal(true);
        } else {
          setModal(false);
        }
      })
      .catch((error) => {
        Swal.fire({
          toast: true,
          position: "top-end",
          text: "El correo ingresado no esta registrado",
          icon: "error",
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
      });
  }

  return (
    <ThemeProvider theme={theme}>
      {modal && <ModalRecuperarContraseña />}
      <Grid container="sm"
       component="main" sx={{ height: "100vh" }}>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage:
              "url(https://indrasolutions.cl/wp-content/uploads/2022/09/login-1-1-min.webp)",
            backgroundRepeat: "cover",

            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />

        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              class="logo-login"
              src="https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png"
              alt="Italian Trulli"
            ></img>

            <Typography
              className="texto-paginas-login"
              component="h1"
              variant="h5"
              sx={{ mt: 4 }}
            >
              ¿Has olvidado tu contraseña?
            </Typography>

            <Typography sx={{ mt: 4 }}>
              Ingresa tu dirección de correo electrónico <br></br> y te enviamos
              un link para reestablecer tu contraseña
            </Typography>

            <Box component="form" sx={{ mt: 4 }}>
              <TextField
                size="large"
                onChange={handleRecoveryPassword}
                margin="normal"
                placeholder="Correo electrónico"
                fullWidth
                label="Email"
                name="login"
                InputProps={{
                  startAdornment: (
                    <InputAdornment className="iconos-labels" position="start">
                      <MarkEmailReadOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
              />
              <LoadingButton
                disableElevation
                disabled={login.login === ""}
                size="large"
                onClick={enviarCorreo}
                fullWidth
                variant="contained"
                sx={{
                  color: "white",
                  backgroundColor: "#e94e1b",
                }}
                loading={loading}
                loadingPosition="end"
              >
                Enviar
              </LoadingButton>
              <Typography
                variant="body2"
                color="textSecondary"
                align="center"
                sx={{ mt: 4 }}
              >
                ¿Nuevo en nuestra plataforma?{" "}
                <Link className="texto-1" to="/registro" variant="body2">
                  Crear una cuenta
                </Link>
              </Typography>
              <Link className="back-session" to="/login" variant="body2">
                <KeyboardArrowLeftIcon className="icons-back icons-form" />{" "}
                Volver
              </Link>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default RecuperarClave;
