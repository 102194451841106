import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import Item from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import axios from "axios";
import Swal from "sweetalert2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";

function PerfilUsuarioComponente() {
  const [informacion, setInformacion] = useState([]);
  const [open, setOpen] = useState(false);

  //stilos
  const Style = {
    variant: "h1",
    component: "div",
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "left",
  };

  //formatear fecha
  const formatearFecha = (fecha) => {
    const fechaFormateada = new Date(fecha).toLocaleDateString("es-ES");
    return fechaFormateada;
  };

  // quitar guion y digito verificador
  const quitarGuion = (rut) => {
    let rutSinGuion = rut.replace("-", "");
    let rutSinGuionYDigito = rutSinGuion.slice(0, -1);
    return rutSinGuionYDigito;
  };

  const nombreCompleto = JSON.parse(localStorage.getItem("nombre"));
  const apellido = JSON.parse(localStorage.getItem("apellido"));
  const rut = JSON.parse(localStorage.getItem("rut"));
  const r_social = JSON.parse(localStorage.getItem("rsocial"));

  // formatear Rut
  function formatRut(rut) {
    const parts = rut.split("-");
    const digits = parts[0].split("").reverse();
    const formattedDigits = digits.reduce((acc, digit, index) => {
      return index % 3 === 0 && index !== 0
        ? `${digit}.${acc}`
        : `${digit}${acc}`;
    }, "");
    return `${formattedDigits}-${parts[1]}`;
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function capitalizeWords(string) {
    return string
      .split(" ")
      .map((word) => capitalizeFirstLetter(word))
      .join(" ");
  }

  useEffect(() => {
    setOpen(true);
    axios
      .post(
        "https://api.liquidez.cl/api/cliente/FichaClientes",
        {
          rut: quitarGuion(JSON.parse(localStorage.getItem("rut"))),
        },
        {
          headers: { ApiKey: process.env.REACT_APP_API_KEY_GVE },
        }
      )
      .then((response) => {
        setInformacion(response.data);
        setOpen(false);
      })
      .catch((error) => {
        setOpen(false);
        if (error.response.status === 400) {
          Swal.fire({
            toast: true,
            position: "center",
            icon: "info",
            title: "¡Rut no registrado!",
            text:
              "Estimado " +
              JSON.parse(localStorage.getItem("nombre")) +
              " " +
              JSON.parse(localStorage.getItem("apellido")) +
              " su rut no se encuentra registrado en nuestra base de datos.",
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true,
          });
        }
      });
  }, []);

  return (
    <>
      <Backdrop
        sx={{
          color: "#DFFEFF",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        cargando información...
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="xl">
        <Card sx={{ maxWidth: 345 }} className="box-componente-perfil">
          <CardMedia
            component="img"
            width={100}
            image="https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png"
            alt="green iguana"
          />
          <CardContent>
            <hr></hr>
            <Typography
              variant="h4"
              component="div"
              gutterBottom
              fontSize="1.2rem"
              fontWeight="bold"
              align="left"
            >
              Datos Personales
            </Typography>

            <Typography style={Style}>
              Nombre: {capitalizeWords(nombreCompleto)}
            </Typography>
            <Typography style={Style}>
              Apellido: {capitalizeWords(apellido)}
            </Typography>
            <Typography style={Style}>Rut: {formatRut(rut)}</Typography>
            <Typography style={Style}>Razón social: {r_social}</Typography>
          </CardContent>
        </Card>

        {informacion.map((informacion) => (
          <Grid item xs={6} md={8}>
            <Item className="box-componente-perfil">
              <Typography
                variant="h4"
                component="div"
                gutterBottom
                fontSize="1.2rem"
                fontWeight="bold"
                align="left"
              >
                Perfil de usuario.
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Typography
                variant="h6"
                component="div"
                gutterBottom
                fontSize="1.2rem"
                fontWeight="bold"
                align="left"
              >
                {informacion.nFantasia}
              </Typography>

              <Divider sx={{ my: 1 }} />

              <Stack
                direction={{ xs: "", sm: "row" }}
                textAlign="left"
                display="flex"
                justifyContent="space-around "
              >
                <Typography>
                  <h6>Rut</h6> <p>{informacion.rutCliente}</p>
                </Typography>
                <Typography>
                  <h6>Giro</h6> <p>{informacion.giroEmpresa}</p>
                </Typography>
                <Typography>
                  <h6>Nombre</h6> <p>{informacion.nFantasia}</p>
                </Typography>
                <Typography>
                  <h6>Fecha de ingreso</h6>{" "}
                  <p>{formatearFecha(informacion.fechaIngreso)}</p>
                </Typography>
              </Stack>
            </Item>
            <Item className="box-componente-perfil">
              <Stack
                direction={{ xs: "", sm: "row" }}
                textAlign="left"
                display="flex"
                justifyContent="space-around "
              >
                <Typography>
                  <h6>Direccion</h6>
                  <p>{informacion.direccion}</p>
                </Typography>
                <Typography>
                  <h6>Comuna</h6> <p>{informacion.comuna}</p>
                </Typography>
                <Typography>
                  <h6>Teléfono</h6> <p>{informacion.telefono}</p>
                </Typography>
                <Typography>
                  <h6>Email</h6> <p>{informacion.email}</p>
                </Typography>
              </Stack>
            </Item>

            <Item className="box-componente-perfil">
              <Stack
                direction={{ xs: "column", sm: "row" }}
                textAlign="left"
                justifyContent="space-around"
              >
                <Typography>
                  <h6>Representante Legal</h6>
                  <p>{informacion.NombreRepresentanteLegal}</p>
                </Typography>
                <Typography>
                  <h6>Rut</h6> <p>{informacion.RutRepresentanteLegal}</p>
                </Typography>
                <Typography>
                  <h6>Email</h6> <p>{informacion.EmailRepresentanteLegal}</p>
                </Typography>
                <Typography>
                  <h6>Ejecutivo</h6> <p>{informacion.ejecutivo}</p>
                </Typography>
              </Stack>
            </Item>
          </Grid>
        ))}
      </Container>
    </>
  );
}

export default PerfilUsuarioComponente;
