import React from "react";
import LogoutButton from "../../componentes/LogoutButton";
import BackHomeButton from "../../componentes/BackHomeButton";
import LogoSidebar from "../../componentes/LogoSidebar";
import Dashboard from "../../componentes/sidebardashboard/Dashboard";

const Sidebar = () => {
  return (
    <div>
      <LogoSidebar />
      <Dashboard />
    </div>
  );
};

Sidebar.LogoutButton = LogoutButton;
Sidebar.BackHomeButton = BackHomeButton;

export default Sidebar;
