import React from 'react';
import ListItem from '@mui/material/ListItem';
import LoginRoundedIcon from '@mui/icons-material/LoginRounded';
import Button from '@mui/material/Button';

function ClearLocalStorage() {
  localStorage.clear();
  window.location.href = "/login";
}

const FooterButtonsSidebar = () => {
  const containerStyles = {

  };

  const buttonStyles = {
    backgroundColor: '#f0f0f0',
    color: '#333',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyles}>
      <ListItem>
        <Button
          fullWidth
          onClick={ClearLocalStorage}
          style={buttonStyles}
          variant="contained"
          size="small"
          endIcon={<LoginRoundedIcon />}
        >
          Desconectar
        </Button>
      </ListItem>
    </div>
  );
};

export default FooterButtonsSidebar;
