import axios from "axios";

const sendEmailCliente = (values, params) => {
  axios
    .post(
      "https://api.sendinblue.com/v3/smtp/email",
      {
        sender: {
          name: `Tu factura N° ${values.folio} ha sido cedida exitosamente`,
          email: "cesaralexander@indrasolutions.cl ",
        },
        to: [
          {
            email: values.email,
            name: values.nombre,
          },
        ],
        subject: `Tu factura N° ${values.folio} ha sido cedida exitosamente`,
        htmlContent: ` <html>
          <table>
            <tr>
              <td colspan="2" style="padding: 35px; padding-top: 10px; padding-bottom: 20px;">
                <p style="margin: 0; line-height: 25px; background: #fff; border-radius: 3px;">
                  <img
                    src="https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png"
                    width="250"
                    text-align="center"
                  />
                  <h1 style="padding: 20px 30px; display: block; border-bottom: 2px solid "black;">
                    se ha realizado la cesión de la factura N° ${values.folio} exitosamente
                    <br />
                    <span style="display: block; margin: 10px;"></span>
                    <h2>Los datos de la cesión son los siguientes:</h2>
                    <p>Nombre: ${values.nombre}</p>
                    <p>Razón Social: ${values.rSocial}</p>
                    <p>Banco: ${values.banco}</p>
                    <p>Tipo de cuenta: ${values.tipoCuenta}</p>
                    <p>Número de cuenta: ${values.nCuenta}</p>
                    <p>Email: ${values.email}</p>
                    <p>Rut: ${values.rut}</p>
                    <p>Fecha de emisión: ${values.fechaEmision}</p>
                    <p>Tipo de venta: ${values.tipoVenta}</p>
                  </h1>
                  <span style="padding: 20px 30px; display: block;">
                    <span style="display: inline-block; line-height: 22px;">
                      Para cualquier ayuda, póngase en contacto con algún ejecutivo de liquidez capital
                      <br />
                      <a href="contacto@liquidez.cl">contacto@liquidez.cl</a>
                    </span>
                    <br />
                    <span style="display: inline-block; margin-top: 20px; line-height: 22px;">
                      Saludos,
                      <br />
                      Liquidez Capital.
                    </span>
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td colspan="2" style="padding: 0 35px 20px; font-size: 12px; color: #c5b99d; line-height: 17px;" align="center">
                El material de este correo electrónico puede ser confidencial, privilegiado y/o protegido por derechos de autor.<br />El uso de este correo electrónico debe limitarse en consecuencia. Si este correo electrónico le ha sido enviado por error, contáctenos de inmediato.
              </td>
            </tr>
          </table>
        </html>
          `,
      },
      {
        headers: {
          "api-key": process.env.REACT_APP_API_KEY_SENDINBLUE,
          accept: "application/json",
          "content-type": "application/json",
        },
      }
    )
    .then((res) => {})
    .catch((err) => {});
};

export default sendEmailCliente;
