import React, { useEffect, useState } from "react";
import axios from "axios";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbar,
  esES,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";


function ResumenLiquidacionComponente() {
  const isMobile = useMediaQuery("(max-width:600px)");

  const columns = [
    {
      field: "idOperEnCurso",
      headerName: "Nº Operación",
      id: "idOperEnCurso",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "cantDoctos",
      headerName: "Documentos",
      id: "cantDoctos",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "fechaOtorgamiento",
      headerName: "Fecha de otorgamiento",
      id: "fechaOtorgamiento",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "anticipable",
      headerName: "Anticipable",
      id: "anticipable",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
  ];

  // quitar guion y digito verificador
  const quitarGuion = (rut) => {
    let rutSinGuion = rut.replace("-", "");
    let rutSinGuionYDigito = rutSinGuion.slice(0, -1);
    return rutSinGuionYDigito;
  };

  const formatNumber = (number) => {
    const formato = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "CLP",
    });
    const output = formato.format(number);
    return output;
  };

  /*  const formatearFecha = (fecha) => {
    const fechaFormateada = new Date(fecha).toLocaleDateString("es-ES");
    return fechaFormateada;
  }; */

  const [info, setInfo] = useState([]);
  const [open, setOpen] = useState(false);

  const formatearFecha = (fecha) => {
    const opciones = {
      year: "numeric",
      month: "long",
      day: "numeric",
      weekday: "long",
    };
    const fechaFormateada = new Date(fecha).toLocaleDateString(
      "es-CL",
      opciones
    );
    return fechaFormateada;
  };

  useEffect(() => {
    setOpen(true);
    axios
      .post(
        "https://api.liquidez.cl/api/cliente/ResumenLiquidacion",
        {
          rut: quitarGuion(JSON.parse(localStorage.getItem("rut"))),
          refreshToken: "refresh_token",
        },
        {
          headers: { ApiKey: process.env.REACT_APP_API_KEY_GVE },
          accept: "application/json",
          "content-type": "application/json",
        }
      )
      .then((response) => {
        const arr = response.data.map((x) => {
          return {
            ...x,
            anticipable: formatNumber(x.anticipable),
            fechaOtorgamiento: new Date(x.fechaOtorgamiento),
          };
        });
        arr.sort(
          (a, b) =>
            new Date(b.fechaOtorgamiento) - new Date(a.fechaOtorgamiento)
        );
        const data = arr.map((x) => {
          return {
            ...x,
            fechaOtorgamiento: formatearFecha(x.fechaOtorgamiento),
          };
        });
        setInfo(data);
        setOpen(false);
      })
      .catch((error) => {
        setOpen(false);
        if (error.response.status === 400) {
          Swal.fire({
            imageUrl:
              "https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png",
            title: "Lo sentimos",
            text: "No hay histórico de operaciones asociadas a este Producto.",
            confirmButtonText: "continuar",
            confirmButtonColor: "#E94E1B",
            showCloseButton: true,
          });
        }
      });
  }, []);

  /* 
  useEffect(() => {
    setOpen(true);
    axios
      .post(
        "https://api.liquidez.cl/api/cliente/ResumenLiquidacion",
        {
          rut: quitarGuion(JSON.parse(localStorage.getItem("rut"))),
          refreshToken: "refresh_token",
        },
        {
          headers: { ApiKey: process.env.REACT_APP_API_KEY_GVE },
          accept: "application/json",
          "content-type": "application/json",
        }
      )
      .then((response) => {
        const arr = response.data.map((x) => {
          return {
            ...x,
            anticipable: formatNumber(x.anticipable),
            fechaOtorgamiento: new Date(x.fechaOtorgamiento),
          };
        });
        arr.sort((a, b) => b.fechaOtorgamiento - a.fechaOtorgamiento);
        setInfo(arr);
        setOpen(false);
      })
      .catch((error) => {
        setOpen(false);
        if (error.response.status === 400) {
          Swal.fire({
            imageUrl:
              "https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png",
            title: "Lo sentimos",
            text: "No hay histórico de operaciones asociadas a este Producto.",
            confirmButtonText: "continuar",
            confirmButtonColor: "#E94E1B",
            showCloseButton: true,
          });
        }
      });
  }, []); */

  //capurar el id de la operacion idOperEnCurso y solicitar la consulta de detalle de la operacion
  const [idOperacion, setIdOperacion] = useState(0);
  const [detalleOperacion, setDetalleOperacion] = useState([]);

  const handleClick = (id) => {
    setIdOperacion(id);
    setOpen(!open);
    axios
      .post(
        "https://api.liquidez.cl/api/cliente/DetalleOP",
        {
          idOperEnCurso: id,
          rut: JSON.parse(localStorage.getItem("rut")),
          refreshToken: "refresh_token",
        },
        {
          headers: { ApiKey: "tFVF7apYe2kEUAat" },
          accept: "application/json",
          "content-type": "application/json",
        }
      )
      .then((response) => {
        setDetalleOperacion(response.data);
        const arr = response.data.map((x, i, a) => {
          a[i]["anticipable"] = formatNumber(x.anticipable);
          a[i]["fechaOtorgamiento"] = formatearFecha(x.fechaOtorgamiento);
        });
      })
      .catch((error) => {});
  };

  const getRowValues = (row) => {
    if (row.field === "idOperEnCurso")
      axios
        .post(
          "https://api.liquidez.cl/api/cliente/DetalleOP",
          {
            rut: "76479083",
            refreshToken: "refresh_token",
          },
          {
            headers: { ApiKey: "tFVF7apYe2kEUAat" },
            accept: "application/json",
            "content-type": "application/json",
          }
        )
        .then((response) => {
          /*  window.location.href = "/detalle-de-operacion"; */
        })
        .catch((error) => {});
  };

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 2,
          pb: 1,
        }}
        display="flex"
      >
        <GridToolbarQuickFilter
          quickFilterParser={(searchInput) =>
            searchInput
              .split(",")
              .map((value) => value.trim())
              .filter((value) => value !== "")
          }
        />
      </Box>
    );
  }
  return (
    <div>
      <Backdrop
        sx={{
          color: "#DFFEFF",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        cargando información...
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="xl">
        <Stack className="box-componente-perfil">
          <Typography
            variant="h1"
            component="div"
            gutterBottom
            fontSize="16px"
            fontWeight="bold"
            align="left"
          >
            Liquidaciones factoring
          </Typography>
          <hr></hr>
          <Box sx={{ height: 750, width: "100%" }}>
            <DataGrid
              CollapseComponent={Collapse}
              rowHeight={50}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              rows={info}
              columns={columns}
              pageSize={15}
              getRowId={(row) =>
                row.idOperEnCurso +
                row.cantDoctos +
                row.fechaOtorgamiento +
                row.anticipable
              }
              onCellClick={getRowValues}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </Box>
        </Stack>
      </Container>
    </div>
  );
}

export default ResumenLiquidacionComponente;
