import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import FacturasCompras from "./analisisempresa/FacturasCompras";
import FacturasVentas from "./analisisempresa/FacturasVentas";
import NotaCreditoVenta from "./analisisempresa/NotaCreditoVenta";
import NotaCreditoCompra from "./analisisempresa/NotaCreditoCompra";
import { fetchUsers } from "./analisisempresa/utils";
import GraficaPeriodoVenta from "./analisisempresa/GraficaPeriodoVenta";
import VentasPorMes from "./analisisempresa/VentasPorMes";
import ComprasPorMes from "./analisisempresa/ComprasPorMes";
import SelectUser from "./analisisempresa/SelectUser";
import Alert from "@mui/material/Alert";
import GraficaComprasVentas from "./analisisempresa/GraficaComprasVentas";
import FacturasCesionadas from "./analisisempresa/FacturasCesionadas";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  borderLeft: "10px solid #00A4EA",
  marginBottom: 15,
  borderRadius: 8,
}));

const AnalisisDeEmpresas = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [facturasCompras, setFacturasCompras] = useState([]);
  const [facturasVentas, setFacturasVentas] = useState([]);

  const [ncrCompras, setNcrCompras] = useState([]);
  const [ncrVentas, setNcrVentas] = useState([]);

  const [ventasPorMes, setVentasPorMes] = useState({});
  const [comprasPorMes, setComprasPorMes] = useState({});

  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");

  const [fechas, setFechas] = useState("");
  const [facturasVentasCecionadas, setFacturasVentasCecionadas] = useState({});

  useEffect(() => {
    const obtenerDatos = async () => {
      setIsLoading(true);
      try {
        const users = await fetchUsers();

        setUsers(users.message);
        setFacturasVentas(facturasVentas);
        setFacturasCompras(facturasCompras);
        setNcrCompras(ncrCompras);
        setNcrVentas(ncrVentas);
        setVentasPorMes(ventasPorMes);
        setComprasPorMes(comprasPorMes);
        setFacturasVentasCecionadas(facturasVentasCecionadas);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    obtenerDatos();
  }, [userId]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid>
        <Item className="box-componente-perfil">
          <Alert sx={{ marginBottom: 2 }} severity="warning">
            <strong>Información:</strong> Para ver los datos de una empresa,
            seleccione un usuario. Solo se muestran datos de los últimos 6 meses
            desde el día actual .
          </Alert>

          <SelectUser
            users={users}
            setUserId={setUserId}
            setFacturasCompras={setFacturasCompras}
            setFacturasVentas={setFacturasVentas}
            setNcrCompras={setNcrCompras}
            setNcrVentas={setNcrVentas}
            setVentasPorMes={setVentasPorMes}
            setComprasPorMes={setComprasPorMes}
            setFechas={setFechas}
            setFacturasVentasCecionadas={setFacturasVentasCecionadas} // Asegúrate de pasar esta función
          />

          {/* Pasamos setUserId a SelectUser para poder actualizar userId */}
        </Item>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Item className="box-componente-perfil">
            {/* facturas de ventas */}
            <FacturasVentas
              facturasVentas={facturasVentas}
              isLoading={isLoading}
            />
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Item className="box-componente-perfil">
            {/* facturas de compras */}
            <FacturasCompras
              facturasCompras={facturasCompras}
              isLoading={isLoading}
            />
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Item className="box-componente-perfil">
            <NotaCreditoVenta
              notaCreditoVenta={ncrVentas}
              isLoading={isLoading}
            />
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Item className="box-componente-perfil">
            <NotaCreditoCompra
              notaCreditoCompra={ncrCompras}
              isLoading={isLoading}
            />
          </Item>
        </Grid>
      </Grid>
      <Item className="box-componente-perfil">
        {/* Grafica de compras en periodo */}
        <Alert
          severity="info"
          sx={{
            marginBottom: 2,
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Top 10 Gráfica de las ventas durante el período seleccionado: {fechas}
        </Alert>
        <GraficaPeriodoVenta facturasVentas={facturasVentas} />
        <Alert
          severity="info"
          sx={{
            marginBottom: 2,
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Evolución de TODAS las compras y ventas durante el período
          seleccionado: {fechas}
        </Alert>
        <GraficaComprasVentas
          ventasPorMes={ventasPorMes}
          comprasPorMes={comprasPorMes}
        />
      </Item>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Item className="box-componente-perfil">
            {/* Ventas por mes */}
            <VentasPorMes ventasPorMes={ventasPorMes} />
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Item className="box-componente-perfil">
            {/* compras por mes  */}
            <ComprasPorMes comprasPorMes={comprasPorMes} />
          </Item>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Item className="box-componente-perfil">
            {/* Ventas por mes */}
            <FacturasCesionadas
              facturasVentasCecionadas={facturasVentasCecionadas}
              isLoading={isLoading}
            />
          </Item>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}></Grid>
      </Grid>
    </Box>
  );
};

export default AnalisisDeEmpresas;
