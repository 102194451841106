import React from "react";
import { Typography, Divider, Box } from "@mui/material";
import { formatCurrency, capitalize } from "./utils";
import Skeleton from "@mui/lab/Skeleton";
import NotDataAlert from "./NotDataAlert";

const FacturasCompras = ({ facturasCompras, isLoading }) => {
  if (isLoading) {
    return (
      <Box>
        <Skeleton variant="text" width="60%" height={30} />
        <Divider sx={{ mt: 2, mb: 2 }} />
        {[...Array(10)].map((_, index) => (
          <Box
            key={index}
            sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
          >
            <Skeleton variant="text" width="40%" />
            <Skeleton variant="text" width="40%" />
          </Box>
        ))}
      </Box>
    );
  }

  const facturasOrdenadas = [...facturasCompras]
    .filter((factura) => factura.razon_social !== "Total General")
    .sort((a, b) => b.monto_total - a.monto_total)
    .slice(0, 10);

  const totalMonto = facturasOrdenadas.reduce(
    (total, nota) => total + nota.monto_total,
    0
  );

  if (facturasOrdenadas.length === 0) {
    // If there are no invoices, show the NotDataAlert component.
    return <NotDataAlert message="No hay facturas de compras para mostrar." />;
  }

  // Render the rest of the content when there are invoices to display.
  return (
    <Box>
      <Typography
        variant="h1"
        component="div"
        gutterBottom
        fontSize="16px"
        fontWeight="bold"
        align="left"
        color={"#1A2027"}
      >
        Top 10 Facturas Compras:
      </Typography>
      <Divider
        sx={{
          backgroundColor: "#1A2027",
          mt: 2,
          mb: 2,
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "bold",
            color: "#1A2027",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Deudor:
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 4,
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "bold",
              color: "#1A2027",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Rut Cliente:
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "bold",
              color: "#1A2027",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Monto Bruto:
          </Typography>
        </Box>
      </Box>
      <Divider
        sx={{
          backgroundColor: "#1A2027",
          mt: 1,
          mb: 1,
        }}
      />

      {facturasOrdenadas.map((factura, index) => (
        <Typography
          key={index}
          sx={{
            fontSize: 12,
            color: "#1A2027",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>{capitalize(factura.razon_social)}</span>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 4,
            }}
          >
            <span>{factura.rut_cliente}</span>
            <span>{formatCurrency(factura.monto_total)}</span>
          </Box>
        </Typography>
      ))}

      <Divider
        sx={{
          backgroundColor: "#1A2027",
          mt: 1,
          mb: 1,
        }}
      />
      <h1
        style={{
          fontSize: "12px",
          fontWeight: "bold",
          color: "#1A2027",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        Total
        <span>{formatCurrency(totalMonto)}</span>
      </h1>
    </Box>
  );
};

export default FacturasCompras;
