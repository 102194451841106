import React from "react";
import PropTypes from "prop-types";
import ResponsiveDrawer from "../../componentes/estructura/ResponsiveDrawer";
import InicioComponente from "../../componentes/dashboard/minicomponentes/InicioComponente";
import Content from "../../componentes/estructura/Content";

const InicioDashboardPage = (props) => {
  const { window } = props;

  return (
    <>
      <ResponsiveDrawer window={window}>
        <Content>
          <InicioComponente />
        </Content>
      </ResponsiveDrawer>
    </>
  );
};

InicioDashboardPage.propTypes = {
  window: PropTypes.func,
};

export default InicioDashboardPage;
