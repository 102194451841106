// NoDataAlert.js

import React from "react";
import { Alert } from "@mui/material";

const NoDataAlert = ({ message }) => {
  return (
    <Alert severity="warning" sx={{ width: "100%", mt: 2 }}>
      {message}
    </Alert>
  );
};

export default NoDataAlert;
