import React, { useState } from "react";
import "./Registro.css";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import Item from "@mui/material/Stack";
import { LoadingButton } from "@mui/lab";
import SendIcon from "@mui/icons-material/Send";
import RegistroExitosoAlert from "../alerts/RegistroExitosoAlert";
import swal from "sweetalert2";
import axios from "axios";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { Formik } from "formik";
import MailRegister from "../mails/MailRegister";
import ReactDOMServer from "react-dom/server";
import { Container } from "@mui/material";

const theme = createTheme();

function Registro() {
  const sendEmailEjecutivo = (values) => {
    const htmlContent = ReactDOMServer.renderToStaticMarkup(
      <MailRegister values={values} />
    );

    axios
      .post(
        "https://api.sendinblue.com/v3/smtp/email",
        {
          sender: {
            name: `Nuevo registro de usuario`,
            email: "no-responder@liquidez.cl",
          },
          to: [
            {
              email: "cesaralexander@indrasolutions.cl",
              name: "cesar muro",
            },
            {
              email: "esanchezabaroa@gmail.com",
              name: "Enrique Sanchez",
            },
            {
              email: "jessica.delarosa@liquidez.cl",
              name: "Jessica de la rosa",
            },
          ],
          subject: `nuevo registro de usuario`,
          htmlContent: htmlContent,
        },
        {
          headers: {
            "api-key": process.env.REACT_APP_API_KEY_SENDINBLUE,
            accept: "application/json",
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {})
      .catch((err) => {});
  };

  const [modal, setModal] = useState(false); //modal de registro exitoso
  const [open, setOpen] = useState(false); //circular progress

  return (
    <ThemeProvider theme={theme}>
      <Backdrop
        sx={{
          color: "#DFFEFF",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        Creando usuario...
        <CircularProgress color="inherit" />
      </Backdrop>
      {modal && <RegistroExitosoAlert />}

      <Grid container sx={{ height: "100vh" }}>
        <Grid
          item
          sm={false}
          md={5}
          sx={{
            backgroundImage:
              "url(https://indrasolutions.cl/wp-content/uploads/2022/09/Group-165-1-1.png)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "",
            backgroundPosition: "bottom",
          }}
        />
        <Grid
          display="flex"
          justifyContent="center"
          alignItems="center"
          item
          xs={12}
          md={7}
          elevation={6}
          square
          component={Paper}
        >
          <Box
            sx={{
              my: 8,
              mx: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              class="logo-login"
              src="https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png"
              alt="Italian Trulli"
            ></img>

            <Typography
              className="texto-paginas-login"
              component="h1"
              variant="h5"
              sx={{ mt: 4, mb: 4 }}
            >
              Completa todos los datos solicitados <br></br> antes de continuar
            </Typography>
            <Formik
              initialValues={{
                sFirstName: "",
                sLastName: "",
                sPhone: "",
                sEmail: "",
                sLogin: "",
                sPassword: "",
                sComments: "",
                sRUT: "",
                iProfileID: 0,
              }}
              validate={(values) => {
                const errores = {};

                //validacion nombre
                if (!values.sFirstName) {
                  errores.sFirstName = "Campo obligatorio";
                } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(values.sFirstName)) {
                  errores.sFirstName = "Solo letras y espacios";
                }
                //validacion apellido
                if (!values.sLastName) {
                  errores.sLastName = "Campo obligatorio";
                } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(values.sLastName)) {
                  errores.sLastName = "Solo letras y espacios";
                }
                //validacion email
                if (!values.sEmail) {
                  errores.sEmail = "Campo obligatorio";
                } else if (
                  !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
                    values.sEmail
                  )
                ) {
                  errores.sEmail = "Por favor ingrese un email válido";
                }
                // Validación RUT CHILENO
                if (!values.sRUT) {
                  errores.sRUT = "Campo obligatorio";
                } else if (!/^([0-9]+-[0-9Kk])$/.test(values.sRUT)) {
                  errores.sRUT = "Por favor ingrese un RUT válido";
                }

                //validacion telefono
                if (!values.sPhone) {
                  errores.sPhone = "Campo obligatorio";
                }
                //validacion login
                if (!values.sLogin) {
                  errores.sLogin = "Campo obligatorio";
                }

                //validacion password
                if (!values.sPassword) {
                  errores.sPassword = "Campo obligatorio";
                }

                return errores;
              }}
              onSubmit={(values, { resetForm }) => {
                setOpen(true);
                axios
                  .post(
                    `${process.env.REACT_APP_HOST_API}liquidez/user/userapp`,
                    {
                      sRUT: values.sRUT,
                      sFirstName: values.sFirstName,
                      sEmail: values.sEmail,
                      sLastName: values.sLastName,
                      sPhone: values.sPhone,
                      sLogin: values.sLogin,
                      sPassword: values.sPassword,
                      sComments: values.sComments,
                    }
                  )
                  .then((response) => {
                    console.log(response.data);
                    if (response.status === 409) {
                      swal.fire({
                        toast: true,
                        position: "top-end",
                        icon: "info",
                        text: "Ocurrió un error creando el usuario por favor inténtelo de nuevo",
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                      });
                      setOpen(false);
                    } else if (
                      response.data.message.sLogin === 'This "login" exists'
                    ) {
                      swal.fire({
                        toast: true,
                        position: "top-end",
                        icon: "info",
                        text: "El usuario ingresado ya existe",
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                      });
                      setOpen(false);
                    } else if (
                      response.data.message.sEmail === 'This "sEmail" exists'
                    ) {
                      swal.fire({
                        toast: true,
                        position: "top-end",
                        icon: "info",
                        text: "El correo electrónico ya existe",
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                      });
                      setOpen(false);
                    } else if (
                      response.data.message.sRUT === 'This "sRUT" exists'
                    ) {
                      swal.fire({
                        toast: true,
                        position: "top-end",
                        icon: "info",
                        text: "El RUT ingresado ya existe",
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                      });
                      setOpen(false);
                    } else if (response.data.status === "fail") {
                      swal.fire({
                        toast: true,
                        position: "top-end",
                        icon: "info",
                        text: "Ocurrió un error creando el usuario",
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                      });
                      setOpen(false);
                    } else if (
                      response.data.message.sEmail ===
                      'The field "sEmail" is invalid.'
                    ) {
                      Swal.fire({
                        toast: true,
                        position: "top-end",
                        icon: "info",
                        text: "Por favor ingrese un correo electrónico válido",
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                      });
                      setOpen(false);
                    } else {
                      setOpen(false);
                      setModal(true);
                      sendEmailEjecutivo(values);
                      resetForm();
                    }
                  })
                  .catch((error) => {
                    console.error("Error:", error);
                    swal.fire({
                      toast: true,
                      position: "top-end",
                      icon: "info",
                      text: "Ocurrió un error creando el usuario",
                      showConfirmButton: false,
                      timer: 2000,
                      timerProgressBar: true,
                    });
                    setOpen(false);
                  });
              }}
            >
              {({
                values,
                handleChange,
                handleSubmit,
                errors,
                handleBlur,
                touched,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Stack direction="row" spacing={2}>
                    <Item>
                      <TextField
                        required
                        fullWidth
                        size="small"
                        name="sFirstName"
                        label="Nombre"
                        type="text"
                        margin="normal"
                        placeholder="Nombre"
                        onChange={handleChange}
                        value={values.sFirstName}
                        onBlur={handleBlur}
                        error={Boolean(errors.sFirstName)}
                        helperText={errors.sFirstName}
                        touched={touched.sFirstName}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="iconos-labels">
                              <AccountCircle />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Item>
                    <Item>
                      <TextField
                        required
                        fullWidth
                        name="sLastName"
                        size="small"
                        label="Apellido"
                        type="text"
                        margin="normal"
                        placeholder="Apellido"
                        onChange={handleChange}
                        value={values.sLastName}
                        onBlur={handleBlur}
                        error={Boolean(errors.sLastName)}
                        helperText={errors.sLastName}
                        touched={touched.sLastName}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="iconos-labels">
                              <AccountCircle />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Item>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Item>
                      <TextField
                        required
                        fullWidth
                        className="input-izquierdo"
                        size="small"
                        name="sPhone"
                        label="Teléfono"
                        type="tel"
                        margin="normal"
                        placeholder="Teléfono"
                        onChange={handleChange}
                        value={values.sPhone}
                        onBlur={handleBlur}
                        error={Boolean(errors.sPhone)}
                        helperText={errors.sPhone}
                        touched={touched.sPhone}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="iconos-labels">
                              <LocalPhoneOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Item>
                    <Item>
                      <TextField
                        required
                        fullWidth
                        type="email"
                        size="small"
                        label="Correo electrónico"
                        name="sEmail"
                        margin="normal"
                        placeholder="Correo electrónico"
                        onChange={handleChange}
                        value={values.sEmail}
                        onBlur={handleBlur}
                        error={Boolean(errors.sEmail)}
                        helperText={errors.sEmail}
                        touched={touched.sEmail}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="iconos-labels">
                              <MarkEmailReadOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Item>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Item>
                      <TextField
                        required
                        fullWidth
                        size="small"
                        name="sRUT"
                        label="RUT empresa"
                        type="text"
                        margin="normal"
                        placeholder="(sin puntos y con guion)"
                        onChange={handleChange}
                        value={values.sRUT}
                        onBlur={handleBlur}
                        error={Boolean(errors.sRUT)}
                        helperText={errors.sRUT}
                        touched={touched.sRUT}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="iconos-labels">
                              <CreditScoreOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Item>
                    <Item>
                      <TextField
                        fullWidth
                        size="small"
                        type="password"
                        label="Clave SII"
                        name="sComments"
                        margin="normal"
                        placeholder="Clave SII"
                        onChange={handleChange}
                        value={values.sComments}
                        onBlur={handleBlur}
                        touched={touched.sComments}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="iconos-labels">
                              <LockIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Item>
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Item>
                      <TextField
                        required
                        fullWidth
                        size="small"
                        className="input-izquierdo"
                        name="sPassword"
                        label="Contraseña"
                        type="password"
                        margin="normal"
                        placeholder="Contraseña"
                        onChange={handleChange}
                        value={values.sPassword}
                        onBlur={handleBlur}
                        error={Boolean(errors.sPassword)}
                        helperText={errors.sPassword}
                        touched={touched.sPassword}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="iconos-labels">
                              <LockIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Item>
                    <Item>
                      <TextField
                        fullWidth
                        required
                        className="input-izquierdo"
                        size="small"
                        type="text"
                        label="Alias"
                        name="sLogin"
                        margin="normal"
                        placeholder="Alias"
                        onChange={handleChange}
                        value={values.sLogin}
                        onBlur={handleBlur}
                        error={Boolean(errors.sLogin)}
                        helperText={errors.sLogin}
                        touched={touched.sLogin}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment className="iconos-labels">
                              <PersonOutlineOutlinedIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Item>
                  </Stack>
                  <LoadingButton
                    /* onClick={registrar} */
                    type="submit"
                    size="medium"
                    fullWidth
                    variant="contained"
                    endIcon={<SendIcon />}
                    sx={{ mt: 3, mb: 2, color: "white", bgcolor: "#e94e1b" }}
                  >
                    Crear cuenta
                  </LoadingButton>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                    sx={{ mt: 4 }}
                    className=""
                  >
                    Al hacer click en Crear cuenta, declaras haber leído y{" "}
                    <br /> muestras tu conformidad con nuestros{" "}
                    <Link className="texto-1" to="#" variant="body2">
                      Términos de Servicio
                    </Link>
                  </Typography>
                  <br />{" "}
                  <KeyboardArrowLeftIcon className="icons-back icons-form" />
                  <Link className="back-session" to="/login" variant="body2">
                    Volver
                  </Link>
                </form>
              )}
            </Formik>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Registro;
