import React, { useEffect, useLayoutEffect, useRef } from "react";
import { create, color, Scrollbar, useTheme } from "@amcharts/amcharts4/core";
import {
  XYChart,
  CategoryAxis,
  ValueAxis,
  ColumnSeries,
  XYCursor,
} from "@amcharts/amcharts4/charts";

const GraficaPeriodoVenta = ({ facturasVentas }) => {
  const chart = useRef(null);

  useLayoutEffect(() => {
    let x = create("chartdivv", XYChart);

    // Excluir "Total General" de los datos
    const facturasSinTotal = facturasVentas.filter(
      (factura) => factura.razon_social !== "Total General"
    );

    // Ordena los datos de mayor a menor
    const sortedFacturas = facturasSinTotal.sort(
      (a, b) => b.monto_total - a.monto_total
    );

    // Toma solo los 10 registros más grandes
    const top10Facturas = sortedFacturas.slice(0, 10);

    // Agrega datos
    x.data = top10Facturas.map((factura) => ({
      name: factura.razon_social,
      sales: factura.monto_total,
    }));

    let categoryAxis = x.xAxes.push(new CategoryAxis());
    categoryAxis.dataFields.category = "name";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.fontSize = 10;
    categoryAxis.renderer.labels.template.wrap = true;
    categoryAxis.renderer.labels.template.maxWidth = 80;

    let valueAxis = x.yAxes.push(new ValueAxis());
    valueAxis.min = 0;
    valueAxis.renderer.labels.template.fontSize = 10;

    // Crea series
    let series = x.series.push(new ColumnSeries());
    series.dataFields.valueY = "sales";
    series.dataFields.categoryX = "name";
    series.name = "Sales";
    series.columns.template.tooltipText = "{categoryX}: [bold]{valueY}[/]";
    series.columns.template.fillOpacity = 0.8;

    // Cambia el color de las columnas
    series.columns.template.fill = color("#e94e1b");

    // Agrega cursor
    x.cursor = new XYCursor();
    x.cursor.behavior = "zoomY";

    // Agrega scrollbars
    x.scrollbarX = new Scrollbar();
    x.scrollbarY = new Scrollbar();

    chart.current = x;

    return () => {
      x.dispose();
    };
  }, [facturasVentas]);

  return <div id="chartdivv" style={{ width: "100%", height: "500px" }}></div>;
};

export default GraficaPeriodoVenta;
