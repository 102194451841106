import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Box, Tooltip } from "@mui/material";
import axios from "axios";

export const UpdateFacturas = () => {
  const [isLoading, setIsLoading] = useState(false);

  // Al cargar el componente, intenta obtener la fecha y hora de la última actualización desde localStorage
  const initialLastUpdated = localStorage.getItem("lastUpdated")
    ? new Date(localStorage.getItem("lastUpdated"))
    : null;
  const [lastUpdated, setLastUpdated] = useState(initialLastUpdated);

  // Leer el valor de 'profile' desde localStorage
  const profile = localStorage.getItem("profile");
  if (profile === "1") {
    // Si profile es igual a '1', no renderizar nada
    return null;
  }

  const imageUrl =
    "https://indrasolutions.cl/wp-content/uploads/2023/08/descarga.png";

  async function updateFacturas() {
    const ID = JSON.parse(localStorage.getItem("id"));
    const TOKEN = JSON.parse(localStorage.getItem("token"));

    const config = {
      headers: { Authorization: TOKEN },
    };

    const bodyParameters = {
      iUserID: ID,
    };

    try {
      const response = await axios.post(
        "https://indra-liquidez.vercel.app/liquidez/facturas/actualizar/facturas",
        bodyParameters,
        config
      );
      console.log(response.data.message);
      if (
        response.data.message === "La clave del SII no se encuentra registrada"
      ) {
        alert(
          "La clave del SII no se encuentra registrada, para actualizar las facturas debe ingresar la clave de SII."
        );
        window.location.reload();
      } else {
        alert("Facturas actualizadas correctamente");
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await updateFacturas();
      const newDate = new Date();
      setLastUpdated(newDate);
      localStorage.setItem("lastUpdated", newDate.toISOString());
    } catch (error) {
      console.error("Error en handleClick:", error);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <Box
        display={window.innerWidth < 600 ? "none" : "flex"}
        alignItems="baseline"
        gap={1}
      >
        <p
          style={{
            fontSize: "12px",
            color: "grey",
            textAlign: "center",
            marginTop: "5px",
            textDecoration: "underline",
          }}
        >
          {lastUpdated
            ? `Última vez actualizado: ${lastUpdated.toLocaleString()}`
            : "Hoy no se ha actualizado"}
        </p>
        <Tooltip title="Con este botón puedes actualizar todas las facturas de tu empresa automáticamente.">
          <LoadingButton
            sx={{
              color: "black",
              backgroundColor: "#E7E7E7",
              borderColor: "#E7E7E7",
              "&:hover": {
                backgroundColor: "#E7E7E7",
                borderColor: "#E7E7E7",
              },
            }}
            size="small"
            variant="outlined"
            onClick={handleClick}
            loading={isLoading}
            endIcon={
              !isLoading ? (
                <img
                  src={imageUrl}
                  alt="Mi Ícono"
                  style={{ width: "24px", height: "24px" }}
                />
              ) : null
            }
          >
            {isLoading ? "Actualizando..." : "Actualizar Facturas"}
          </LoadingButton>
        </Tooltip>
      </Box>
    </div>
  );
};
