import React, { useEffect, useState } from "react";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Chip from "@mui/material/Chip";
import {
  TextField,
  Typography,
  Stack,
  Alert,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const API_URL =
  "https://indra-liquidez.vercel.app/liquidez/facturas/all-cedidas";

const formatDate = (dateString) => {
  const [day, month, year] = dateString.split("-");
  return `${year}-${month}-${day}`;
};

const ConsultaTable = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fechaDesde, setFechaDesde] = useState("");
  const [fechaHasta, setFechaHasta] = useState("");
  const [error, setError] = useState(false);
  const [filtroEstado, setFiltroEstado] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
        body: JSON.stringify({
          fecha_desde: formatDate(fechaDesde),
          fecha_hasta: formatDate(fechaHasta),
        }),
      });

      const data = await response.json();
      const rowsData = Object.entries(data.message).flatMap(
        ([clientId, clientData]) => {
          const clientRow = {
            id: clientId,
            razon_social: clientData.razon_social,
            rut: clientData.rut,
            nombre: clientData.nombre,
            apellido: clientData.apellido,
          };

          const facturaRows = clientData.data.map((dataItem, index) => ({
            id: `${clientId}_${index}`,
            razon_social: dataItem.sRazonSocial,
            rut: dataItem.sRUT,
            nombre: dataItem.sFirstName,
            apellido: dataItem.sLastName,
            iNumDoc: dataItem.iNumDoc,
            status: dataItem.status,
            dFecha: dataItem.dFecha,
            deudor: dataItem.deudor,
            fecha_emision: dataItem.fecha_emision,
            monto: dataItem.monto,
            parentId: clientId,
          }));

          return [clientRow, ...facturaRows];
        }
      );

      // Filtrar las filas según el estado seleccionado
      const filteredRows = filtroEstado
        ? rowsData.filter((row) => row.status === filtroEstado)
        : rowsData;

      setRows(filteredRows);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleEnviarClick = () => {
    if (fechaDesde && fechaHasta && fechaHasta > fechaDesde) {
      setError(false);
      fetchData();
    } else {
      setError(true);
    }
  };

  const handleFiltroEstadoChange = (event) => {
    setFiltroEstado(event.target.value);
  };

  const columns = [
    { field: "razon_social", headerName: "Razón Social", width: 200 },
    { field: "rut", headerName: "RUT", width: 130 },
    { field: "nombre", headerName: "Nombre", width: 150 },
    { field: "apellido", headerName: "Apellido", width: 150 },
    { field: "iNumDoc", headerName: "NumDoc", width: 100 },
    {
      field: "status",
      headerName: "Estado",
      width: 150,
      renderCell: (params) => {
        const facturaStatus = params.value;

        if (facturaStatus === "ACCEPTED") {
          return <Chip color="success" size="small" label="Cedida" />;
        } else if (facturaStatus === "REJECTED") {
          return <Chip color="error" size="small" label="Rechazada" />;
        } else if (facturaStatus === "REVIEW") {
          return <Chip color="warning" size="small" label="En revisión" />;
        } else if (facturaStatus === "CESSION_APROVED") {
          return (
            <Chip color="warning" size="small" label="Disponible para ceder" />
          );
        } else {
          return null;
        }
      },
    },
    { field: "dFecha", headerName: "Último cambio de estado", width: 200 },
    { field: "deudor", headerName: "Deudor", width: 250 },
    { field: "fecha_emision", headerName: "Fecha Emisión", width: 200 },
    { field: "monto", headerName: "Monto", width: 130 },
  ];

  return (
    <Stack spacing={2} elevation={0}>
      <Stack elevation={0} className="box-componente-perfil">
        <Alert severity="info" sx={{ width: "100%", mb: 4 }}>
          Seleccione un rango de fechas para visualizar el estatus de cada
          factura
        </Alert>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 1, md: 1 }}
        >
          <Stack elevation={0}>
            <Stack>
              <FormControl fullWidth size="small" sx={{ minWidth: 200 }}>
                <InputLabel
                  id="demo-simple-select-label"
                  className="label-filtro-estado"
                >
                  Filtrar por Estado
                </InputLabel>
                <Select
                  value={filtroEstado}
                  onChange={handleFiltroEstadoChange}
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="ACCEPTED">Cedida</MenuItem>
                  <MenuItem value="REJECTED">Rechazada</MenuItem>
                  <MenuItem value="REVIEW">En revisión</MenuItem>
                  <MenuItem value="CESSION_APROVED">
                    Disponible para ceder
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Stack>
          <Stack elevation={0}>
            <TextField
              required
              fullWidth
              size="small"
              label="Fecha Desde"
              name="date_init"
              type="date"
              value={fechaDesde}
              onChange={(e) => setFechaDesde(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              error={error && !fechaDesde}
            />
            {error && !fechaDesde && (
              <Typography variant="caption" color="error">
                Este campo es requerido
              </Typography>
            )}
          </Stack>
          <Stack elevation={0}>
            <TextField
              required
              fullWidth
              size="small"
              label="Fecha Hasta"
              name="date_end"
              type="date"
              value={fechaHasta}
              onChange={(e) => setFechaHasta(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              error={error && !fechaHasta}
            />
            {error && !fechaHasta && (
              <Typography variant="caption" color="error">
                Este campo es requerido
              </Typography>
            )}
            {error && fechaHasta <= fechaDesde && (
              <Typography variant="caption" color="error">
                La fecha "Hasta" debe ser mayor a la fecha "Desde"
              </Typography>
            )}
          </Stack>
          <Stack elevation={0}>
            <LoadingButton
              textTransform="none"
              className="btn-buscar"
              size="medium"
              fullWidth
              type="submit"
              variant="contained"
              onClick={handleEnviarClick}
            >
              Consultar
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
      {loading ? (
        <div>Cargando datos ...</div>
      ) : (
        <div
          style={{ height: 800, width: "100%" }}
          className="box-componente-perfil"
        >
          <DataGridPro
            treeData
            rows={rows}
            columns={columns}
            getTreeDataPath={(row) => {
              const path = [row.id];
              let parentId = row.parentId;

              while (parentId !== undefined) {
                path.unshift(parentId);
                parentId = rows.find((item) => item.id === parentId)?.parentId;
              }

              return path;
            }}
            autoPageSize
          />
        </div>
      )}
    </Stack>
  );
};

export default ConsultaTable;
