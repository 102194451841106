
import Navbar from "../componentes/Navbar/Navbar"
import Registro from "../componentes/Registro/Registro"

// eslint-disable-next-line import/no-anonymous-default-export
export default function ()  {
    return (
      <div>
          <Navbar/> 
          <Registro/>
      </div>
    )
  }