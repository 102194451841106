import React from "react";

const MailAnticipo = ({ params }) => {
  const nombre = JSON.parse(localStorage.getItem("nombre"));
  const apellido = JSON.parse(localStorage.getItem("apellido"));
  const phone = JSON.parse(localStorage.getItem("phone"));
  const rut = JSON.parse(localStorage.getItem("rut"));

  return (
    <>
    <div
    >
      <div>
        <div

        >
          <div
          >
            <div
            >
              <img
                src="https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png"
                alt="logo"
                width="150"
                style={{
                  maxWidth: "100%",
                  marginTop: "30px",
                }}
              />
            </div>
            <div style={{ textAlign: "center",}}>
              <h1
                style={{
                  color: "#143785",
                  fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                  fontSize: "2em",
                  fontWeight: 700,
                  lineHeight: "34px",
                  textAlign: "left",
                  marginBottom: 0,
                  marginTop: 0,
                }}
              >
                Nuevo mensaje de anticipo de factura
              </h1>
            </div>
            <div style={{ textAlign: "left",}}>
              <p style={{ color: "#143785" }}>Nombre: {nombre}</p>
              <p style={{ color: "#143785" }}>Apellido: {apellido}</p>
              <p style={{ color: "#143785" }}>Telefono: {phone}</p>
              <p style={{ color: "#143785" }}>Rut: {rut}</p>
              <h1
                style={{
                  color: "#143785",
                  fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                  fontSize: "2em",
                  fontWeight: 700,
                  lineHeight: "34px",
                  marginBottom: 0,
                  marginTop: 0,
                }}
              >
                Datos de la Factura
              </h1>
              <p style={{ color: "#143785" }}>
                Deudor: {params.row.razon_social}
              </p>
              <p style={{ color: "#143785" }}>
                Rut Deudor:  {params.row.rut_cliente}
              </p>
              <p style={{ color: "#143785" }}>
                Num Documento:  {params.row.folio}
              </p>
              <p style={{ color: "#143785" }}>
                Valor Documento:  {params.row.monto_total} 
              </p>
              <p style={{ color: "#143785" }}>
                Fecha Documento:  {params.row.fecha_emision}
              </p>
            </div>
            <div
              style={{ borderBottom: "1px solid #D3D1D1", padding: "30px 0" }}
            />

            <div style={{ textAlign: "center", padding: "30px 36px" }}>
              <p
                style={{
                  color: "#143785",
                  fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  marginBottom: 0,
                  marginTop: 0,
                }}
              >
                <a
                  href="https://www.liquidez.cl/"
                  style={{ color: "#143785", textDecoration: "none" }}
                >
                  Liquidez Capital
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default MailAnticipo;
