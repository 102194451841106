import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Link, NavLink } from "react-router-dom";
import { Divider, Typography } from "@mui/material";
import FooterButtonsSidebar from "../FooterButtonsSidebar";
import "./Dashboard.css";

const Dashboard = () => {
  const styleIcons = {
    width: "20px",
    height: "20px",
  };

  return (
    <>
      <List>
        <NavLink to="/" style={{ textDecoration: "none", color: "white" }}>
          <ListItem disablePadding className="list-item-dashboard">
            <ListItemButton>
              <ListItemIcon style={styleIcons}>
                <img
                  src="https://indrasolutions.cl/wp-content/uploads/2022/09/element-3.png"
                  alt="icon-dashboard"
                />
              </ListItemIcon>
              <li className="titulos-dashboard">
                <span>Inicio</span>
              </li>
            </ListItemButton>
          </ListItem>
        </NavLink>

        {/* <NavLink
          to="/sincronizar"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem disablePadding>
            <ListItemButton className="list-item-dashboard">
              <ListItemIcon style={styleIcons}>
                <img
                  src="https://indrasolutions.cl/wp-content/uploads/2022/09/arrow-3.png"
                  alt="icon-dashboard"
                />
              </ListItemIcon>
              <li className="titulos-dashboard">
                <span>Registro SII</span>
              </li>
            </ListItemButton>
          </ListItem>
        </NavLink> */}

        {/*  <NavLink
          to="/subir-certificado"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem disablePadding>
            <ListItemButton className="list-item-dashboard">
              <ListItemIcon style={styleIcons}>
                <img
                  src="https://indrasolutions.cl/wp-content/uploads/2022/09/arrow-3.png"
                  alt="icon-dashboard"
                />
              </ListItemIcon>

              <li className="titulos-dashboard">
                <span>Subir Certificado SII</span>
              </li>
            </ListItemButton>
          </ListItem>
        </NavLink> */}

        <NavLink
          to="/ver-facturas"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem disablePadding>
            <ListItemButton className="list-item-dashboard">
              <ListItemIcon style={styleIcons}>
                <img
                  src="https://indrasolutions.cl/wp-content/uploads/2022/09/direct.png"
                  alt="icon-dashboard"
                />
              </ListItemIcon>

              <li className="titulos-dashboard">
                <span>Ver facturas SII</span>
              </li>
            </ListItemButton>
          </ListItem>
        </NavLink>

        <NavLink
          to="/facturas-para-ceder"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem disablePadding>
            <ListItemButton className="list-item-dashboard">
              <ListItemIcon style={styleIcons}>
                <img
                  src="https://indrasolutions.cl/wp-content/uploads/2022/09/direct.png"
                  alt="icon-dashboard"
                />
              </ListItemIcon>

              <li className="titulos-dashboard">
                <span>Facturas disponibles para cesión</span>
              </li>
            </ListItemButton>
          </ListItem>
        </NavLink>

        <NavLink
          to="/cartera-vigente-factoring"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem disablePadding>
            <ListItemButton className="list-item-dashboard">
              <ListItemIcon style={styleIcons}>
                <img
                  src="https://indrasolutions.cl/wp-content/uploads/2022/10/document-text.png"
                  alt="icon-dashboard"
                />
              </ListItemIcon>
              <li className="titulos-dashboard">
                <span>Cartera Vigente Factoring</span>
              </li>
            </ListItemButton>
          </ListItem>
        </NavLink>

        <NavLink
          to="/cartera-vigente-mutuos"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem disablePadding>
            <ListItemButton className="list-item-dashboard">
              <ListItemIcon style={styleIcons}>
                <img
                  src="https://indrasolutions.cl/wp-content/uploads/2022/10/document-text.png"
                  alt="icon-dashboard"
                />
              </ListItemIcon>
              <li className="titulos-dashboard">
                <span>Cartera Vigente Mutuos</span>
              </li>
            </ListItemButton>
          </ListItem>
        </NavLink>

        <NavLink
          to="/liquidaciones-factoring"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem disablePadding>
            <ListItemButton className="list-item-dashboard">
              <ListItemIcon style={styleIcons}>
                <img
                  src="https://indrasolutions.cl/wp-content/uploads/2022/10/clipboard-text.png"
                  alt="icon-dashboard"
                />
              </ListItemIcon>
              <li className="titulos-dashboard">
                <span>Liquidaciones Factoring</span>
              </li>
            </ListItemButton>
          </ListItem>
        </NavLink>

        <NavLink
          to="/liquidaciones-mutuos"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem disablePadding>
            <ListItemButton className="list-item-dashboard">
              <ListItemIcon style={styleIcons}>
                <img
                  src="https://indrasolutions.cl/wp-content/uploads/2022/10/task-square.png"
                  alt="icon-dashboard"
                />
              </ListItemIcon>

              <li className="titulos-dashboard">
                <span>Liquidaciones Mutuos</span>
              </li>
            </ListItemButton>
          </ListItem>
        </NavLink>

        <NavLink
          to="/perfil-usuario"
          style={{ textDecoration: "none", color: "white" }}
        >
          <ListItem disablePadding>
            <ListItemButton className="list-item-dashboard">
              <ListItemIcon style={styleIcons}>
                <img
                  src="https://indrasolutions.cl/wp-content/uploads/2022/10/profile-2user.png"
                  alt="icon-dashboard"
                />
              </ListItemIcon>
              <li className="titulos-dashboard">
                <span>Perfil de usuario</span>
              </li>
            </ListItemButton>
          </ListItem>
        </NavLink>

        <Divider
          sx={{
            backgroundColor: "#E5E5E5",
            height: "1px",
            width: "100%",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        />
        {localStorage.getItem("profile") === "1" ? (
          <>
            <Typography
              sx={{ color: "white", textAlign: "left", fontSize: "14px" }}
              ml={2}
              mb={2}
            >
              Panel de administrador
            </Typography>
            <NavLink
              to="/facturas-pendientes"
              style={{ textDecoration: "none", color: "white" }}
            >
              <ListItem disablePadding>
                <ListItemButton className="list-item-dashboard">
                  <ListItemIcon>
                    <img
                      src="https://indrasolutions.cl/wp-content/uploads/2022/09/direct.png"
                      alt="icon-dashboard"
                    />
                  </ListItemIcon>

                  <li className="titulos-dashboard">
                    <span>Panel de administración</span>
                  </li>
                </ListItemButton>
              </ListItem>
            </NavLink>

            <NavLink
              to="/historico-de-estatus-facturas"
              style={{ textDecoration: "none", color: "white" }}
            >
              <ListItem disablePadding>
                <ListItemButton className="list-item-dashboard">
                  <ListItemIcon>
                    <img
                      src="https://indrasolutions.cl/wp-content/uploads/2022/09/direct.png"
                      alt="icon-dashboard"
                    />
                  </ListItemIcon>

                  <li className="titulos-dashboard">
                    <span>Ver estatus de facturas por cliente</span>
                  </li>
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink
              to="/analisis-de-empresa"
              style={{ textDecoration: "none", color: "white" }}
            >
              <ListItem disablePadding>
                <ListItemButton className="list-item-dashboard">
                  <ListItemIcon>
                    <img
                      src="https://indrasolutions.cl/wp-content/uploads/2022/09/direct.png"
                      alt="icon-dashboard"
                    />
                  </ListItemIcon>

                  <li className="titulos-dashboard">
                    <span>Análisis de empresa</span>
                  </li>
                </ListItemButton>
              </ListItem>
            </NavLink>
            <NavLink
              to="/facturas-5-dias"
              style={{ textDecoration: "none", color: "white" }}
            >
              <ListItem disablePadding>
                <ListItemButton className="list-item-dashboard">
                  <ListItemIcon>
                    <img
                      src="https://indrasolutions.cl/wp-content/uploads/2022/09/direct.png"
                      alt="icon-dashboard"
                    />
                  </ListItemIcon>

                  <li className="titulos-dashboard">
                    <span>Ver facturas emitidas de últimos 5 dias</span>
                  </li>
                </ListItemButton>
              </ListItem>
            </NavLink>
          </>
        ) : null}
        <Divider
          sx={{
            backgroundColor: "#E5E5E5",
            height: "1px",
            width: "100%",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        />
        <FooterButtonsSidebar />
      </List>
    </>
  );
};

export default Dashboard;
