import React, { useEffect, useState } from "react";
import { DataGrid, esES } from "@mui/x-data-grid";
import axios from "axios";
import { useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import FormularioCesion from "../../SIIComponentes/FormularioCesion";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
export default function FacturasParaCeder() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const TOKEN = localStorage.getItem("token").replace(/"/g, "");
  const [info, setInfo] = useState([]);
  const [open3, setOpen3] = useState(false);
  const [buttonParams, setButtonParams] = useState();
  const [open, setOpen] = useState(false);
  const ID_USER = localStorage.getItem("id");
  const RUT = localStorage.getItem("rut").replace(/"/g, "");
  const TIPO_OPERACION = "VENTA";

  useEffect(() => {
    setOpen(true);
    axios
      .post(
        "https://indra-liquidez.vercel.app/liquidez/facturas/por-ceder",
        {
          iUserID: ID_USER,
          rut: RUT,
          tipo_operacion: TIPO_OPERACION,
          fecha_desde: "01-01-2024",
          fecha_hasta: "31-12-2024",
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: TOKEN,
          },
        }
      )
      .then((response) => {
        setInfo(response.data.facturas);
        //convertir a formato de moneda chileno
        for (let i = 0; i < response.data.facturas.length; i++) {
          response.data.facturas[i].monto_total = new Intl.NumberFormat(
            "de-DE",
            { style: "currency", currency: "CLP" }
          ).format(response.data.facturas[i].monto_total);
        }
        setOpen(false);
      })
      .catch((error) => {
        setOpen(false);
      });
  }, []);

  const columns = [
    {
      field: "razon_social",
      headerName: "Deudor",
      id: "razon_social",
      flex: 1,
      minWidth: isMobile ? 100 : 200,
    },
    {
      field: "fecha_emision",
      headerName: "Fecha Emisión",
      id: "fecha_emision",
      flex: 1,
      minWidth: isMobile ? 100 : 200,
    },
    {
      field: "rut_cliente",
      headerName: "Rut Deudor",
      id: "rut_cliente",
      flex: 1,
      minWidth: isMobile ? 100 : 200,
    },
    {
      field: "folio",
      headerName: "Num Documento",
      id: "folio",
      width: 200,
      flex: 1,
      minWidth: isMobile ? 100 : 200,
    },
    {
      field: "monto_total",
      headerName: "Valor Documento",
      id: "monto_total",
      flex: 1,
      minWidth: isMobile ? 100 : 200,
    },
    {
      field: "Ceder factura",
      headerName: "Ceder Factura",
      variant: "outlined",
      flex: 1,
      minWidth: isMobile ? 100 : 200,
      renderCell: (params) => {
        return (
          <Button
            sx={{ bgcolor: "#143785", borderColor: "#143785" }}
            size="small"
            variant="contained"
            onClick={() => {
              setOpen3(true);
              setButtonParams(params);
            }}
          >
            Ceder Factura
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <Backdrop
        sx={{
          color: "#DFFEFF",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        cargando información...
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="xl">
        <Stack sx={{ width: "100%" }} className="box-componente-perfil">
          <Typography
            mb={1}
            fontSize="16px"
            variant="h3"
            fontFamily={"Roboto"}
            align="left"
            fontWeight={600}
          >
            Facturas disponibles para ceder
            <Alert
              severity="info"
              sx={{ mt: 2 }}
              style={{ fontSize: "15px", fontWeight: "500" }}
            >
              <b>Nota:</b> Las facturas que se muestran a continuación son
              facturas que se encuentran en estado de{" "}
              <strong>"Disponible para cesión".</strong>
            </Alert>
            <hr />
          </Typography>
          <Stack>
            <Box sx={{ height: 750, width: "100%" }}>
              <DataGrid
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                rows={info}
                columns={columns}
                getRowId={(row) => row.monto_total + row.folio}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
              />
            </Box>
          </Stack>
        </Stack>
      </Container>
      <FormularioCesion
        buttonParams={buttonParams}
        open={open3}
        handleFormClose={() => setOpen3(false)}
      />
    </>
  );
}
