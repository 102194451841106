import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import Item from "@mui/material/Stack";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import IosShareIcon from "@mui/icons-material/IosShare";
import { Link } from "react-router-dom";
import axios from "axios";
import UploadButton from "../../SIIComponentes/UploadButton";
import Divider from "@mui/material/Divider";
import { LoadingButton } from "@mui/lab";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import ModalSendSIIAdmin from "../../modals/ModalSendSIIAdmin";

export const InicioComponente = () => {
  const ID = JSON.parse(localStorage.getItem("id"));
  const BEARER = JSON.parse(localStorage.getItem("token"));

  const [usuario, setUsuario] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_HOST_API}liquidez/user/userapp/${ID}`, {
        headers: {
          Authorization: `${BEARER}`,
        },
      })
      .then((res) => {
        if (res.data.status === "success") {
          setUsuario(res.data.message);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const NOMBRE = JSON.parse(localStorage.getItem("nombre"));
  const NOMBRE_MAYUSCULAS = NOMBRE ? NOMBRE.toUpperCase() : "";
  const R_SOCIAL = JSON.parse(localStorage.getItem("rsocial"));

  return (
    <>
      <Container maxWidth="xl" className="box-componente-perfil">
        {usuario.sComments === "" && <ModalSendSIIAdmin />}

        <Typography
          fontSize="16px"
          variant="h3"
          component="div"
          align="left"
          fontWeight="bold"
          mb={1}
        >
          ! Hola
          <span style={{ color: "#143785" }}> {NOMBRE_MAYUSCULAS}</span> !
        </Typography>
        <Typography
          fontSize="16px"
          variant="h3"
          component="div"
          align="left"
          fontWeight="bold"
        >
          Razón social: {R_SOCIAL}
        </Typography>
        <hr />
        <Alert severity="success" fontSize="16px" align="left">
          A través de nuestra plataforma podrás vendernos (ceder) tus facturas
          para obtener liquidez inmediata, para lo cual contamos con dos
          opciones: una rápida, simple y transparente, sincronizada con el SII,
          o bien una alternativa manual que tambien sirve para hacernos llegar
          la copia de tus facturas y cotizar.
        </Alert>
        <hr />
        <Typography
          mb={1}
          fontSize="16px"
          variant="h3"
          fontFamily={"Roboto"}
          align="left"
          fontWeight={600}
        >
          Modo sincronizado con el SII
        </Typography>
        <Alert severity="info" style={{ fontSize: "15px", fontWeight: "500" }}>
          Primero sincroniza en muy pocos pasos, si ya lo hiciste, cotiza y
          continua avanzando.
        </Alert>
        <hr />
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          direction="row"
          useFlexGap
          flexWrap="wrap"
        >
          {/*  <Card
            className="box-componente-perfil"
            sx={{ minWidth: 325, maxWidth: 325 }}
            elevation={3}
          >
            <CardContent>
              <Typography
                fontFamily={"Roboto"}
                gutterBottom
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                textAlign="center"
                fontWeight={600}
                variant="h2"
                fontSize={16}
              >
                <SyncIcon sx={{ fontSize: 30, color: "#E94E1B" }} />
                Sincronizar con el SII
              </Typography>

              <Typography textAlign="left" fontSize="16px">
                Sincroniza tu cuenta SII con nostros.
                <br></br>
                <br></br>
                <br></br>
                <Divider
                  sx={{
                    margin: "10px 0",
                  }}
                />
                <LoadingButton
                  disableElevation
                  size="small"
                  fullWidth
                  sx={{
                    backgroundColor: "#143785",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#143785",
                      color: "white",
                    },
                  }}
                  variant="contained"
                  component={Link}
                  to="/sincronizar"
                >
                  Sincronizar
                </LoadingButton>
              </Typography>
            </CardContent>
          </Card> */}
          <Card
            className="box-componente-perfil"
            sx={{ minWidth: 325, maxWidth: 325 }}
            elevation={3}
          >
            <CardContent>
              <Typography
                fontFamily={"Roboto"}
                gutterBottom
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                textAlign="center"
                fontWeight={600}
                variant="h2"
                fontSize="16px"
              >
                <RemoveRedEyeIcon sx={{ fontSize: 30, color: "#E94E1B" }} />
                Visualiza tus ofertas
              </Typography>
              <Typography textAlign="left" fontSize="16px">
                Según nuestra evaluación, te informaremos sobre los posibles
                anticipos de las facturas sincronizas.
                <Divider
                  sx={{
                    margin: "10px 0",
                  }}
                />
                <LoadingButton
                  disableElevation
                  fullWidth
                  size="small"
                  component={Link}
                  to="/ver-facturas"
                  sx={{
                    backgroundColor: "#143785",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#143785",
                      color: "white",
                    },
                  }}
                  variant="contained"
                >
                  cotizar facturas
                </LoadingButton>
              </Typography>
            </CardContent>
          </Card>
          <Card
            className="box-componente-perfil"
            sx={{ minWidth: 325, maxWidth: 325 }}
            elevation={3}
          >
            <CardContent>
              <Typography
                fontFamily={"Roboto"}
                gutterBottom
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                textAlign="center"
                fontWeight={600}
                variant="h2"
                fontSize={16}
              >
                <IosShareIcon
                  sx={{
                    alignContent: "left",
                    fontSize: 30,
                    color: "#E94E1B",
                  }}
                />
                Cede tus facturas
              </Typography>
              <Typography textAlign="left" fontSize="16px">
                Luego de nuestra aprobación, podrás ceder tus facturas con
                nosotros.
                <br></br>
                <br></br>
                <Divider
                  sx={{
                    margin: "10px 0",
                  }}
                />
                <LoadingButton
                  disableElevation
                  fullWidth
                  size="small"
                  sx={{
                    backgroundColor: "#143785",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#143785",
                      color: "white",
                    },
                  }}
                  variant="contained"
                  component={Link}
                  to="/facturas-para-ceder"
                >
                  CEDE TUS FACTURAS
                </LoadingButton>
              </Typography>
            </CardContent>
          </Card>
        </Stack>
        <Divider
          sx={{
            margin: "10px 0",
          }}
        />
        <Typography
          mb={1}
          fontSize="16px"
          variant="h3"
          fontFamily={"Roboto"}
          align="left"
          fontWeight={600}
        >
          Modo manual
        </Typography>
        <Divider
          sx={{
            margin: "10px 0",
          }}
        />
        <Stack
          spacing={{ xs: 1, sm: 2 }}
          direction="row"
          useFlexGap
          flexWrap="wrap"
        >
          <Card
            className="box-componente-perfil"
            sx={{ minWidth: 325, maxWidth: 325 }}
            elevation={3}
          >
            <CardContent>
              <Typography
                fontFamily={"Roboto"}
                gutterBottom
                display="flex"
                justifyContent="space-around"
                alignItems="center"
                textAlign="center"
                fontWeight={600}
                variant="h2"
                fontSize={16}
              >
                <CloudUploadIcon sx={{ fontSize: 30, color: "#E94E1B" }} />
                Sube tus facturas
              </Typography>
              <Typography textAlign="left" fontSize="16px">
                Sube aquí tu factura para solicitar una cotización.
                <Divider
                  sx={{
                    margin: "10px 0",
                  }}
                />
                {<UploadButton />}
              </Typography>
            </CardContent>
          </Card>

          <Card sx={{ minWidth: 325, maxWidth: 325 }} elevation={0}></Card>
          <Card sx={{ minWidth: 325, maxWidth: 325 }} elevation={0}></Card>
        </Stack>
        <hr />
        <Item
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
          alignItems="baseline"
        >
          <div>
            <Alert
              severity="info"
              style={{ fontSize: "15px", fontWeight: "500" }}
              action={
                <Button
                  sx={{
                    fontSize: "15px",
                  }}
                  variant="contained"
                  disableElevation
                  color="inherit"
                  size="small"
                  onClick="ga('send', 'event', 'telefono', 'llamada', 'header');"
                  href="tel:+56995403429"
                >
                  +56 9 9540 3429
                </Button>
              }
            >
              Si tienes dudas, contáctanos a través de nuestros medios de
              comunicación disponibles.
            </Alert>
          </div>
        </Item>
      </Container>
    </>
  );
};

export default InicioComponente;
