import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Alert,
  CircularProgress,
  Button,
  Box,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import axios from "axios";
import * as XLSX from "xlsx";

const API_URL =
  "https://indra-liquidez.vercel.app/liquidez/facturas/consultar/ultimos-documentos";

const ConsultaTable = () => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [currentDate, setCurrentDate] = useState("");
  const [pastDates, setPastDates] = useState([]);
  const TOKEN = JSON.parse(localStorage.getItem("token"));

  const downloadExcel = () => {
    const ventasData = [];

    Object.entries(data).forEach(([companyName, rows]) => {
      const ventas = rows.filter((row) => row.sTipoOperacion === "VENTA");

      if (ventas.length > 0) {
        ventasData.push(...ventas);
      }
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(ventasData);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Ventas");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "ventas.xlsx";
    a.click();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = JSON.parse(localStorage.getItem("token"));
        const response = await axios.get(API_URL, {
          headers: {
            "Content-Type": "application/json",
            Authorization: TOKEN,
          },
        });
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // Get current date
    const currentDate = new Date().toLocaleDateString();

    // Get past 5 days
    const pastDates = Array.from({ length: 5 }, (_, index) => {
      const date = new Date();
      date.setDate(date.getDate() - index);
      return date.toLocaleDateString();
    });

    setCurrentDate(currentDate);
    setPastDates(pastDates);
  }, []);

  //funcion para colocar el formato de moneda chileno a los numeros

  const formatNumber = (num) => {
    return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
  };

  const formatCurrency = (amount) => {
    return `${formatNumber(amount)} CLP`;
  };

  const renderRows = (rows) => {
    const ventas = rows.filter((row) => row.sTipoOperacion === "VENTA");

    const sortedVentas = ventas.sort((a, b) => {
      const dateA = new Date(a.dFechaEmision).getTime();
      const dateB = new Date(b.dFechaEmision).getTime();
      return dateA - dateB;
    });

    return (
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>RUT</TableCell>
              <TableCell>NumDoc</TableCell>
              <TableCell>Fecha Emisión</TableCell>
              <TableCell>Monto</TableCell>
              <TableCell>Razón Social Destino</TableCell>
              <TableCell>Tipo Documento</TableCell>
              <TableCell>Tipo Operación</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedVentas.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.sRut_Cliente}</TableCell>
                <TableCell>{row.sNroDocumento}</TableCell>
                <TableCell>{row.dFechaEmision}</TableCell>
                <TableCell>{formatCurrency(row.monto)}</TableCell>
                <TableCell>{row.sRazonSocial}</TableCell>
                <TableCell>{row.sTipoDocumento}</TableCell>
                <TableCell>{row.sTipoOperacion}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div>
      <Alert severity="info" style={{ marginBottom: "1rem" }}>
        <div style={{ marginLeft: "1rem" }}>
          Solo se muestran los últimos 5 días de facturas desde el día actual y
          5 días hacia atrás. Fecha actual: {currentDate}. Fechas pasadas:{" "}
          {pastDates.join(", ")}
        </div>
      </Alert>
      <Box display="flex" mb={2}>
        <Button
          className="btn-buscar"
          onClick={downloadExcel}
          variant="contained"
          color="primary"
        >
          Descargar Facturas
        </Button>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        Object.entries(data)
          .sort(([companyNameA], [companyNameB]) =>
            companyNameA.localeCompare(companyNameB)
          )
          .map(([companyName, rows], index) => {
            const ventas = rows.filter((row) => row.sTipoOperacion === "VENTA");

            if (ventas.length === 0) {
              // Omitir clientes sin facturas de ventas
              return null;
            }

            return (
              <Accordion key={index}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6">{companyName}</Typography>
                </AccordionSummary>
                <AccordionDetails>{renderRows(ventas)}</AccordionDetails>
              </Accordion>
            );
          })
      )}
    </div>
  );
};

export default ConsultaTable;
