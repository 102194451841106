import React from "react";
import Box from "@mui/material/Box";
import InicioComponente from "../../componentes/dashboard/minicomponentes/InicioComponente";

const drawerWidth = 240;

const Content = () => {
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        width: { sm: `calc(100% - ${drawerWidth}px)` },
      }}
    >
      <InicioComponente/>
    </Box>
  );
};

export default Content;
