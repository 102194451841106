import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import UploadIcon from "@mui/icons-material/Upload";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

export default function UploadButton() {
  const ID = JSON.parse(localStorage.getItem("id"));
  const BEARER = JSON.parse(localStorage.getItem("token"));
  //traer info del usuario
  const [usuario, setUsuario] = useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_HOST_API}liquidez/user/userapp/${ID}`, {
        headers: {
          Authorization: `${BEARER}`,
        },
      })
      .then((res) => {
        setUsuario(res.data);
      })
      .catch((err) => {});
  }, []);

  const handleUpload = () => {
    Swal.fire({
      title: "Subir Archivo",
      text: "Seleccione un archivo pdf",
      imageUrl:
        "https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png",
      html: `<input type="file" id="file" accept="application/pdf" />`,
      showCancelButton: true,
      confirmButtonText: "Enviar",
      confirmButtonColor: "#143785",
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#e94e1b",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const file = Swal.getPopup().querySelector("#file").files[0];
        if (!file) {
          Swal.showValidationMessage(`Seleccione un archivo`);
        }
        return file;
      },
    }).then((result) => {
      //convertir archivo a base64
      if (result.isConfirmed) {
        setOpen(true);
        const reader = new FileReader();
        reader.readAsDataURL(result.value);
        reader.onload = () => {
          const base64 = reader.result.split(",")[1];
          //enviar mail con archivo adjunto
          axios
            .post(
              "https://api.sendinblue.com/v3/smtp/email",
              {
                sender: {
                  name: "Carga de factura manual",
                  email: "no-responder@liquidez.cl",
                },
                to: [
                  {
                    email: "esanchezabaroa@gmail.com",
                    name: "Enrique Sanchez",
                  },
                  {
                    email: "cesaralexander@indrasolutions.cl",
                    name: "Cesar Amuro",
                  },
                ],

                attachment: [
                  {
                    name: result.value.name,
                    content: base64,
                  },
                ],
                subject: "Carga de factura manual",
                htmlContent: `<img
                src="https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png"
                alt="logo"
                width="auto"
              />
                <h3>
                    Se ha adjuntado una factura manualmente por el usuario ${JSON.parse(
                      localStorage.getItem("nombre")
                    )} ${JSON.parse(localStorage.getItem("apellido"))}
                </h3>
                <p style="color: #143785;" >Nombre: ${JSON.parse(
                  localStorage.getItem("nombre")
                )}</p>
                <p style="color: #143785;" >Apellido: ${JSON.parse(
                  localStorage.getItem("apellido")
                )}</p>
                <p style="color: #143785;" >Telefono: ${JSON.parse(
                  localStorage.getItem("phone")
                )}</p>
                <p style="color: #143785;" >Rut: ${JSON.parse(
                  localStorage.getItem("rut")
                )}</p>
                `,
              },
              {
                headers: {
                  "api-key": process.env.REACT_APP_API_KEY_SENDINBLUE,
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              setOpen(false);
              if (result.isConfirmed) {
                Swal.fire({
                  imageUrl:
                    "https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png",
                  title: "Factura enviada",
                  text: "Se ha enviado la factura a un ejecutivo de Liquidez",
                  confirmButtonText: "Aceptar",
                  confirmButtonColor: "#143785",
                });
              }
            })
            .catch((err) => {});
        };
      }
    });
  };
  return (
    <>
      <LoadingButton
        disableElevation
        size="small"
        fullWidth
        style={{ backgroundColor: "#143785", color: "white" }}
        onClick={handleUpload}
        startIcon={<UploadIcon />}
        variant="contained"
      >
        Subir documento
      </LoadingButton>

      <Backdrop
        sx={{
          color: "#DFFEFF",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        Estamos enviando tu factura a un ejecutivo de Liquidez...
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
