import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import CompanyLogin from "../SIIComponentes/CompanyLogin";

var moment = require("moment");
var now = moment().format("DD/MM/YYYY");

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const SincronizacionesComponente = () => {
  return (
    <>
    <CompanyLogin/>
    </>
  );
};

export default SincronizacionesComponente;
