/* eslint-disable no-unused-vars */
import React from 'react'
import Navbar from '../componentes/Navbar/Navbar'
import Login from '../componentes/Login/Login'
import Footer from '../componentes/footer/Footer'


// eslint-disable-next-line import/no-anonymous-default-export
export default function ()  {
  return (
    <div>
      <Navbar/>
      <Login/>
      <Footer/>
    </div>
  )
}