import React from "react";
// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  //aca ira la pagina de error 404

  return (
    <>
      <h1
        variant="contained"
        className="text-error"
        style={{
          color: "white",
        }}
      >
        Oops algo salió mal, no se encontró la página
      </h1>
    </>
  );
}
