import React from 'react';
import { CDBFooter, CDBFooterLink, CDBBox} from 'cdbreact';

export default function Footer() {
  return (
    <div className='footer-propiedades'>
      
    </div>
  );
};

