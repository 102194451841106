import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import axios from "axios";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Item from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";

export const CarteraVigenteMutuosComponente = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const Styles = {
    component: "div",
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "center",
  };
  const Styles2 = {
    component: "div",
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "left",
  };

  const columns = [
    {
      field: "idOperEnCurso",
      headerName: "Nº Operación",
      id: "idOperEnCurso",
      align: "left",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "moneda",
      headerName: "Moneda",
      id: "moneda",
      align: "left",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "valorCuota",
      headerName: "Saldo Cuota",
      id: "valorCuota",
      align: "left",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "fechaOtorg",
      headerName: "Fecha de otorgamiento.",
      id: "fechaOtorg",
      align: "left",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "fechaVcto",
      headerName: "Fecha de vencimiento.",
      id: "fechaVcto",
      align: "left",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "estado",
      headerName: "Estado",
      id: "estado",
      align: "left",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
  ];

  const formatNumber = (number) => {
    const formato = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "CLP",
    });
    const output = formato.format(number);

    return output;
  };

  // obtener el nombre del mes, día del mes, año, hora

  var moment = require("moment");
  var now = moment().format("DD/MM/YYYY HH:mm:ss");

  const formatearFecha = (fecha) => {
    const fechaFormateada = new Date(fecha).toLocaleDateString("es-ES");

    return fechaFormateada;
  };

  const [info, setInfo] = useState([]);
  const [totalSaldoCuotas, setTotalSaldoCuotas] = useState(0);
  const [cuotasVigentes, setCuotasVigentes] = useState(0);
  const [cuotasMorosas, setCuotasMorosas] = useState(0);
  const [totalVigente, setTotalVigente] = useState(0);
  const [totalMoroso, setTotalMoroso] = useState(0);
  const [open, setOpen] = useState(false);

  // quitar guion y digito verificador
  const quitarGuion = (rut) => {
    let rutSinGuion = rut.replace("-", "");
    let rutSinGuionYDigito = rutSinGuion.slice(0, -1);
    return rutSinGuionYDigito;
  };

  useEffect(() => {
    setOpen(true);
    axios
      .post(
        "https://api.liquidez.cl/api/cliente/CarteraVigenteM",
        {
          rut: quitarGuion(JSON.parse(localStorage.getItem("rut"))),
          refreshToken: "refresh_token",
        },
        {
          headers: { ApiKey: process.env.REACT_APP_API_KEY_GVE },
          accept: "application/json",
          "content-type": "application/json",
        }
      )

      .then((response) => {
        let total1 = 0,
          total2 = 0,
          total3 = 0,
          total4 = 0,
          total5 = 0;
        const arr = response.data.map((x, i, a) => {
          total1 += x.valorCuota;
          /*total2 += x.estado === "Vigente" ? 1 : 0;*/
          /*total3 += x.estado === "Moroso" ? 1 : 0;*/
          total4 += x.estado === "Vigente" ? x.valorCuota : 0; //suma de cuotas vigentes
          total5 += x.estado === "Moroso" ? x.valorCuota : 0; //suma de cuotas morosas
          a[i]["valorCuota"] = formatNumber(x.valorCuota); //formato de moneda
          a[i]["fechaOtorg"] = formatearFecha(x.fechaOtorg); //formato de fecha
          a[i]["fechaVcto"] = formatearFecha(x.fechaVcto); //formato de fecha
          return a[i];
        });

        setTotalSaldoCuotas(total1);
        setCuotasVigentes(total2);
        setCuotasMorosas(total3);
        setTotalVigente(total4);
        setTotalMoroso(total5);
        setInfo(arr);
        setOpen(false);
      })
      .catch((error) => {
        setOpen(false);
        if (error.response.status === 400) {
          Swal.fire({
            imageUrl:
              "https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png",
            title: "Lo sentimos",
            text: "No hay documentos vigentes en cartera.",
            confirmButtonText: "continuar",
            confirmButtonColor: "#E94E1B",
            showCloseButton: true,
          });
        }
      });
  }, []);

  return (
    <>
      <Backdrop
        sx={{
          color: "#DFFEFF",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        cargando información...
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="xl">
        <Grid item xs={6} md={8}>
          <Item className="box-componente-perfil">
            <Typography
              variant="h1"
              component="div"
              gutterBottom
              fontSize="16px"
              fontWeight="bold"
              align="left"
            >
              Cartera vigente mutuos
              <hr></hr>
            </Typography>
            <Typography style={Styles2}>
              Cartera vigente de cliente al: <Typography>{now}</Typography>
            </Typography>
            <hr></hr>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              justifyContent="space-evenly"
              alignItems="center"
            >
              <Typography style={Styles}>
                Saldo Cuotas<p>{formatNumber(totalSaldoCuotas)}</p>
              </Typography>
              <Divider color="grey" orientation="vertical" flexItem />
              <Typography style={Styles}>
                Cuotas Vigentes <p>{formatNumber(totalVigente)}</p>
              </Typography>
              <Divider color="grey" orientation="vertical" flexItem />
              <Typography style={Styles}>
                Cuotas Morosas <p>{formatNumber(totalMoroso)}</p>
              </Typography>
            </Stack>
          </Item>
        </Grid>
      </Container>

      <Container maxWidth="xl">
        {" "}
        <Stack className="box-componente-perfil">
          <Typography
            variant="h1"
            component="div"
            gutterBottom
            fontSize="16px"
            fontWeight="bold"
            align="left"
          >
            {" "}
            Detalle
          </Typography>
          <hr></hr>
          <Box sx={{ height: 750, width: "100%" }}>
            <DataGrid
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              rows={info}
              columns={columns}
              align="center"
              rowHeight={38}
              getRowId={(row) =>
                row.idOperEnCurso +
                row.moneda +
                row.valorCuota +
                row.fechaOtorg +
                row.fechaVcto +
                row.estado
              }
              components={{
                Toolbar: GridToolbar,
              }}
              
            />
          </Box>
        </Stack>
      </Container>
    </>
  );
};
