import React, { useState, useEffect } from "react";
import axios from "axios";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Formik, Field } from "formik";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import LoadingButton from "@mui/lab/LoadingButton";
import { TextField } from "@mui/material";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";

const FacturasPendientes = () => {
  const [loading, setLoading] = useState(false);
  /* const [openModal, setOpenModal] = useState(true); */
  const [selectedRut, setSelectedRut] = useState("");

  const formatNumber = (number) => {
    const formato = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "CLP",
    });
    const output = formato.format(number);
    return output;
  };

  const columns = [
    {
      field: "razon_social",
      headerName: "Deudor",
      id: "razon_social",
      flex: 1,
    },
    {
      field: "fecha_emision",
      headerName: "Fecha Emisión",
      id: "fecha_emision",
      flex: 1,
    },
    {
      field: "rut_cliente",
      headerName: "Rut Deudor",
      id: "rut_cliente",
      flex: 1,
    },
    {
      field: "folio",
      headerName: "Num Documento",
      id: "folio",
      flex: 1,
    },
    {
      field: "monto_total",
      headerName: "Valor Documento",
      id: "monto_total",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Estado",
      id: "status",
      flex: 1,

      renderCell: (params) => {
        return (
          <div>
            {facturasAprobadas.find(
              (factura) => factura.iNumDoc === params.row.folio
            )?.status === "ACCEPTED" ? (
              <Chip color="success" size="small" label="Cedida" />
            ) : facturasAprobadas.find(
                (factura) => factura.iNumDoc === params.row.folio
              )?.status === "REJECTED" ? (
              <Chip color="error" size="small" label="Rechazada" />
            ) : facturasAprobadas.find(
                (factura) => factura.iNumDoc === params.row.folio
              )?.status === "REVIEW" ? (
              <Chip color="warning" size="small" label="En revisión" />
            ) : facturasAprobadas.find(
                (factura) => factura.iNumDoc === params.row.folio
              )?.status === "CESSION_APROVED" ? (
              <Chip
                color="warning"
                size="small"
                label="Disponible para ceder"
              />
            ) : (
              <Chip
                variant="outlined"
                color="info"
                size="small"
                label="Por cotizar"
              />
            )}
          </div>
        );
      },
    },
  ];

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

  // const [facturas, setFacturas] = useState([]);
  const [infodata, setInfodata] = useState([]);
  const BEARER = JSON.parse(localStorage.getItem("token"));
  const [facturasAprobadas, setFacturasAprobadas] = useState([]);
  const URL_FACTURA_CEDIBLE = `${process.env.REACT_APP_HOST_API}liquidez/facturas/cedidas/`;
  const [informacion, setInformacion] = useState([]);
  const [open, setOpen] = useState(false);
  const URL_VERDOCUMENTOS =
    "https://indra-liquidez.vercel.app/liquidez/facturas/documentos";
  const URL_FACTURA_CAMBIO_ESTATUS = `${process.env.REACT_APP_HOST_API}liquidez/facturas/cedidas`;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_HOST_API}liquidez/user/userapp`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: JSON.parse(localStorage.getItem("token")),
            },
          }
        );

        // Filtrar usuarios con sComments vacío
        const filteredUsers = response.data.message
          .filter((user) => user.sComments && user.sComments !== "")
          .sort((a, b) => a.sRazonSocial.localeCompare(b.sRazonSocial));

        setInformacion(filteredUsers);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  const [info, setInfo] = useState([]);
  const [userSelectedID, setUserSelectedID] = useState("");

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    // Devolvemos la fecha formateada en formato 'DD-MM-YYYY'
    return `${day < 10 ? "0" + day : day}-${
      month < 10 ? "0" + month : month
    }-${year}`;
  };

  return (
    <>
      {/* {openModal && <ModalSendSIIAdmin />} */}
      <Backdrop
        sx={{
          color: "#DFFEFF",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        cargando información...
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={6} md={4}>
            <Stack className="box-componente-perfil">
              <Stack>
                <Formik
                  initialValues={{
                    iNumDoc: "",
                    iUserID: informacion.iUserID,
                    rut: informacion,
                  }}
                  onSubmit={(values, {}) => {
                    setTimeout(() => {
                      setLoading(true);
                      setOpen(true);
                      axios
                        .put(URL_FACTURA_CAMBIO_ESTATUS, values, {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: JSON.parse(
                              localStorage.getItem("token")
                            ),
                          },
                        })
                        .then((response) => {
                          if (response.data.status === "fail") {
                            Swal.fire({
                              toast: true,
                              position: "top-end",
                              icon: "error",
                              text: `error al cambiar el estado de la factura, ${response.data.message}`,
                              showConfirmButton: false,
                              timer: 4000,
                              zIndex: 10000,
                            });
                            setLoading(false);
                            setOpen(false);
                          } else {
                            Swal.fire({
                              toast: true,
                              position: "top-end",
                              icon: "success",
                              text: "El estado de la factura ha sido cambiado con exito",
                              showConfirmButton: false,
                              timer: 3000,
                              zIndex: 10000,
                            });
                            setLoading(false);
                            setOpen(false);
                          }
                        })
                        .catch((error) => {});
                    }, 500);
                  }}
                >
                  {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <Grid>
                        <Stack spacing={2.7}>
                          <Typography
                            variant="h1"
                            component="div"
                            gutterBottom
                            fontSize="16px"
                            fontWeight="bold"
                            align="left"
                          >
                            Habilitar, Rechazar o Aprobar Facturas
                          </Typography>
                          <hr></hr>
                          <FormControl fullWidth>
                            <Typography
                              mb={1}
                              fontSize="16px"
                              variant="h3"
                              fontFamily={"Roboto"}
                              align="left"
                              fontWeight={600}
                              color="black"
                            >
                              Rut cliente
                            </Typography>
                            <Select
                              native
                              values={values.iUserID}
                              fullWidth
                              required
                              size="small"
                              select
                              name="iUserID"
                              onChange={(event) => {
                                const selectedOption =
                                  event.target.options[
                                    event.target.selectedIndex
                                  ];
                                const selectedRut =
                                  selectedOption.getAttribute("data-rut");
                                const selectedValue = event.target.value;
                                const [iUserID, rut] =
                                  selectedValue.split(" - ");
                                handleChange(event);
                                setFieldValue("iUserID", iUserID);
                                setFieldValue("rut", rut);
                              }}
                            >
                              <option value="" disabled selected>
                                Seleccione un cliente
                              </option>
                              {informacion.map((item) => (
                                <option
                                  key={item.id}
                                  value={`${item.iUserID} - ${item.sRUT}`}
                                >
                                  {item.sRUT} - {item.sRazonSocial}
                                </option>
                              ))}
                            </Select>
                          </FormControl>
                          <TextField
                            required
                            sx={{
                              color: "black",
                              backgroundColor: "white",
                            }}
                            fullWidth
                            id="iNumDoc"
                            name="iNumDoc"
                            label="Numero de Documento"
                            value={values.iNumDoc}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            variant="outlined"
                            size="small"
                          />
                          <FormControl>
                            <Field
                              label="Seleccione el estado de la factura"
                              as={TextField}
                              select
                              required
                              name="status"
                              value={values.status}
                              onChange={handleChange}
                              size="small"
                              variant="outlined"
                              error={values.status === ""}
                              helperText={
                                values.status === ""
                                  ? "Por favor, seleccione un estado de factura"
                                  : ""
                              }
                            >
                              <MenuItem value="ACCEPTED">Cedida</MenuItem>
                              <MenuItem value="REJECTED">Rechazada</MenuItem>
                              <MenuItem value="REVIEW">En revisión</MenuItem>
                              <MenuItem value="CESSION_APROVED">
                                Disponible para ceder
                              </MenuItem>
                            </Field>
                          </FormControl>
                          <LoadingButton
                            loading={loading}
                            loadingPosition="end"
                            type="submit"
                            className="btn-buscar"
                            variant="contained"
                            size="small"
                            endIcon={<SearchIcon />}
                          >
                            actualizar estatus
                          </LoadingButton>
                        </Stack>
                      </Grid>
                    </form>
                  )}
                </Formik>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6} md={8}>
            <Stack className="box-componente-perfil">
              <Typography
                variant="h1"
                component="div"
                gutterBottom
                fontSize="16px"
                fontWeight="bold"
                align="left"
              >
                Estatus de Facturas
                <hr></hr>
              </Typography>
              <Alert
                variant="filled"
                sx={{ mb: 2 }}
                textAlign="left"
                severity="success"
                icon={<CheckCircleIcon fontSize="inherit" />}
              >
                <strong>Cedida:</strong> Factura ya cedida a liquidez
              </Alert>
              <Alert
                sx={{ mb: 2 }}
                textAlign="left"
                variant="filled"
                severity="error"
                icon={<CancelIcon fontSize="inherit" />}
              >
                <strong>Rechazado:</strong> Factura no cumplió con los
                requisitos de liquidez para ser cedida
              </Alert>
              <Alert
                sx={{ mb: 2 }}
                variant="filled"
                textAlign="left"
                severity="info"
                icon={<AccessTimeIcon fontSize="inherit" />}
              >
                <strong>Revision:</strong> Factura en proceso de revisión por
                parte de liquidez
              </Alert>
              <Alert
                sx={{ mb: 2 }}
                variant="filled"
                textAlign="left"
                severity="warning"
                icon={<ThumbUpAltIcon fontSize="inherit" />}
              >
                <strong>Disponible para ceder:</strong> Factura ya aprobada por
                liquidez y lista para ser cedida
              </Alert>
            </Stack>
          </Grid>
        </Grid>
      </Container>

      {
        //segunda seccion
      }
      <Container maxWidth="xl">
        <Formik
          initialValues={{
            emp_rut: "",
            operation_type: "VENTA",
            date_init: "",
            date_end: "",
          }}
          onSubmit={(values, { setSubmitting }) => {
            function formatDate(date) {
              var dd = String(date.getDate()).padStart(2, "0");
              var mm = String(date.getMonth() + 1).padStart(2, "0");
              var yyyy = date.getFullYear();

              return dd + "-" + mm + "-" + yyyy;
            }

            let date_init = new Date(values.date_init);
            date_init = new Date(
              date_init.getTime() + date_init.getTimezoneOffset() * 60000
            );

            let date_end = new Date(values.date_end);
            date_end = new Date(
              date_end.getTime() + date_end.getTimezoneOffset() * 60000
            );

            const date_init_formatted = formatDate(date_init);
            const date_end_formatted = formatDate(date_end);
            setTimeout(() => {
              setOpen(true);
              setSubmitting(false);
              axios
                .post(
                  URL_VERDOCUMENTOS,
                  {
                    rut: values.emp_rut,
                    tipoOperacion: "VENTA",
                    fechaDesde: date_init_formatted, // formatear fecha
                    fechaHasta: date_end_formatted, // formatear fecha
                    tipoDocumento: "33",
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: BEARER,
                    },
                  }
                )
                .then((response) => {
                  if (
                    response.data.error ===
                    "Ocurrió un error consultando los documentos. La clave de SII no se encuentra registrado en el sistema"
                  ) {
                    alert(
                      "El usuario que desea consultar aún no actualiza su clave SII, por favor intente mas tarde"
                    );
                    setOpen(false);
                  } else if (response.data.length === 0) {
                    alert(
                      "El usuario que desea consultar no tiene documentos en el periodo seleccionado"
                    );
                    setOpen(false);
                  } else {
                    setInfo(response.data);
                    response.data.forEach((item) => {
                      item.monto_neto = formatNumber(item.monto_neto);
                      item.monto_total = formatNumber(item.monto_total);
                    });
                    setOpen(false);
                  }

                  setInfodata(response.data.data);
                  return axios.get(`${URL_FACTURA_CEDIBLE}${userSelectedID}`, {
                    headers: {
                      Authorization: JSON.parse(localStorage.getItem("token")),
                    },
                  });
                })

                .then((response) => {
                  if (
                    response.data.error ===
                    "Ocurrió un error consultando los documentos. La clave de SII no se encuentra registrado en el sistema"
                  ) {
                    alert(
                      "Ocurrió un error consultando los documentos. La clave de SII no se encuentra registrado en el sistema"
                    );

                    setOpen(false);
                  } else {
                    setOpen(false);
                    setFacturasAprobadas(response.data.message);
                  }
                })
                .catch((error) => {});
            }, 500);
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid
                item
                xs={6}
                md={8}
                className="box-componente-perfil"
                elevation={0}
              >
                <Stack spacing={2} elevation={0}>
                  <Item elevation={0}>
                    <Alert severity="info" sx={{ width: "100%", mb: 4 }}>
                      Selecciona el rango de fechas para consultar las facturas
                      cargadas en el sistema.
                    </Alert>
                    <Stack
                      direction={{ xs: "column", sm: "row" }}
                      spacing={{ xs: 1, sm: 1, md: 1 }}
                    >
                      <Item elevation={0}>
                        <FormControl fullWidth required>
                          <Select
                            fullWidth
                            required
                            size="small"
                            select
                            name="emp_rut"
                            onChange={(event) => {
                              handleChange(event);
                              setUserSelectedID(
                                event.target.selectedOptions[0].getAttribute(
                                  "id"
                                )
                              );
                            }}
                            value={values.emp_rut}
                            native
                          >
                            <option value="" disabled>
                              {"Seleccione un cliente"}
                            </option>
                            {informacion.map((item) => (
                              <option
                                key={item.iUserID}
                                value={`${item.sRUT}`}
                                id={item.iUserID}
                              >
                                {`${item.sRUT} - ${item.sRazonSocial}`}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </Item>
                      <Item elevation={0}>
                        <TextField
                          required
                          fullWidth
                          size="small"
                          label="Fecha Desde"
                          name="date_init"
                          type="date"
                          value={values.date_init}
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        ></TextField>
                      </Item>
                      <Item elevation={0}>
                        <TextField
                          required
                          fullWidth
                          size="small"
                          label="Fecha Hasta"
                          name="date_end"
                          type="date"
                          value={values.date_end}
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        ></TextField>
                      </Item>
                      <Item elevation={0}>
                        <LoadingButton
                          className="btn-buscar"
                          size="medium"
                          fullWidth
                          type="submit"
                          variant="contained"
                          sx
                        >
                          Consultar
                        </LoadingButton>
                      </Item>
                    </Stack>
                  </Item>
                </Stack>
              </Grid>
            </form>
          )}
        </Formik>
      </Container>
      <Container maxWidth="xl">
        <Grid sx={{ height: 750, width: "100%" }}>
          <DataGrid
            rows={info}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            getRowId={(row) =>
              row.razon_social + row.rut_cliente + row.folio + row.monto_total
            }
            components={{
              Toolbar: GridToolbar,
            }}
          />
        </Grid>
      </Container>
    </>
  );
};

export default FacturasPendientes;
