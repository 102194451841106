import React from "react";
import { Typography, Divider, Box } from "@mui/material";
import { formatCurrency, capitalize } from "./utils";
import Skeleton from "@mui/lab/Skeleton";
import NotDataAlert from "./NotDataAlert";

const NotaCreditoVenta = ({ notaCreditoVenta, isLoading }) => {
  if (isLoading) {
    return (
      <Box>
        <Skeleton variant="text" width="60%" height={30} />
        <Divider sx={{ mt: 2, mb: 2 }} />
        {[...Array(10)].map((_, index) => (
          <Box
            key={index}
            sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}
          >
            <Skeleton variant="text" width="40%" />
            <Skeleton variant="text" width="40%" />
          </Box>
        ))}
      </Box>
    );
  }

  const notaCreditoOrdenada = [...notaCreditoVenta]
    .filter((nota) => nota.razon_social !== "Total General")
    .sort((a, b) => b.monto_total - a.monto_total)
    .slice(0, 10);

  //solo se suman las facturas que se muestran en pantalla
  const totalMonto = notaCreditoOrdenada.reduce(
    (total, nota) => total + nota.monto_total,
    0
  );

  if (notaCreditoOrdenada.length === 0) {
    // If there are no nota crédito venta, show the NotDataAlert component.
    return (
      <NotDataAlert message="No hay notas de crédito venta para mostrar." />
    );
  }

  const renderedNotaCreditoVenta = notaCreditoOrdenada.map((nota, index) => (
    <Typography
      key={index}
      sx={{
        fontSize: 12,
        color: "#1A2027",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <span>{capitalize(nota.razon_social)}</span>
      <span
        sx={{
          fontWeight: "bold",
          textAlign: "right",
        }}
      >
        {formatCurrency(nota.monto_total)}
      </span>
    </Typography>
  ));

  return (
    <Box>
      <Typography
        variant="h1"
        component="div"
        gutterBottom
        fontSize="16px"
        fontWeight="bold"
        align="left"
        color={"#1A2027"}
      >
        Top 10 Nota Credito Venta:
      </Typography>
      <Divider
        sx={{
          backgroundColor: "#1A2027",
          mt: 2,
          mb: 2,
        }}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "bold",
            color: "#1A2027",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Deudor:
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "bold",
            color: "#1A2027",
            display: "flex",
          }}
        >
          Monto Bruto:
        </Typography>
      </Box>
      <Divider
        sx={{
          backgroundColor: "#1A2027",
          mt: 1,
          mb: 1,
        }}
      />
      {renderedNotaCreditoVenta}
      <Divider
        sx={{
          backgroundColor: "#1A2027",
          mt: 1,
          mb: 1,
        }}
      />
      <Box>
        <h1
          style={{
            fontSize: "12px",
            fontWeight: "bold",
            color: "#1A2027",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Total
          <span>{formatCurrency(totalMonto)}</span>
        </h1>
      </Box>
    </Box>
  );
};

export default NotaCreditoVenta;
