export const opcionesCuenta = [
  {
    value: "Cuenta Corriente",
    label: "CUENTA CORRIENTE",
  },
  {
    value: "Cuenta Vista",
    label: "CUENTA VISTA",
  },
  {
    value: "Cuenta Rut",
    label: "CUENTA RUT",
  },
  {
    value: "Cuenta Chequera Electrónica",
    label: "CUENTA CHEQUERA ELECTRÓNICA",
  },
];

export const opcionesBanco = [
  {
  value: "Banco de Chile",
  label: "BANCO DE CHILE",
  },
  {
  value: "Banco Internacional",
  label: "BANCO INTERNACIONAL",
  },
  {
  value: "Scotiabank Chile",
  label: "SCOTIABANK CHILE",
  },
  {
  value: "Banco de Crédito e Inversiones",
  label: "BANCO DE CRÉDITO E INVERSIONES",
  },
  {
  value: "Banco Bice",
  label: "BANCO BICE",
  },
  {
  value: "HSBC Bank (Chile)",
  label: "HSBC BANK (CHILE)",
  },
  {
  value: "Banco Santander-Chile",
  label: "BANCO SANTANDER-CHILE",
  },
  {
  value: "Itaú Corpbanca",
  label: "ITAÚ CORPBANCA",
  },
  {
  value: "Banco Security",
  label: "BANCO SECURITY",
  },
  {
  value: "Banco Falabella",
  label: "BANCO FALABELLA",
  },
  {
  value: "Banco Ripley",
  label: "BANCO RIPLEY",
  },
  {
  value: "Banco Consorcio",
  label: "BANCO CONSORCIO",
  },
  {
  value: "Scotiabank Azul",
  label: "SCOTIABANK AZUL",
  },
  {
  value: "Banco BTG Pactual Chile",
  label: "BANCO BTG PACTUAL CHILE",
  },
  {
  value: "Banco Estado",
  label: "BANCO ESTADO",
  },

  ];