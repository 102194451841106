import React, { useState, useEffect } from "react";
import Icon from "@mui/icons-material/ExpandMore"; // Import the icon
import { formatCurrency } from "./utils"; // Import the formatCurrency function
import { Typography, Skeleton } from "@mui/material";
import { Divider } from "antd";

const VentasPorMes = ({ comprasPorMes }) => {
  // Define the order of months
  const monthOrder = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
    // Add more months as needed
  ];

  // Initialize state to keep track of expanded months
  const [expandedMonths, setExpandedMonths] = useState({});
  // Initialize state to keep track of loading state
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate API call to fetch data
    const fetchData = async () => {
      try {
        // Your API fetch code here
        // For demonstration purposes, I'm using a setTimeout to simulate API call
        await new Promise((resolve) => setTimeout(resolve, 1500));
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  // Function to toggle the expanded state of a month
  const toggleMonth = (mes) => {
    setExpandedMonths((prevState) => ({
      ...prevState,
      [mes]: !prevState[mes],
    }));
  };

  // Function to compare and sort months based on the defined order
  const compareMonths = (a, b) => {
    const aIndex = monthOrder.indexOf(a);
    const bIndex = monthOrder.indexOf(b);
    return aIndex - bIndex;
  };

  // Sort the ventasPorMes object by month
  const sortedVentasPorMes = Object.fromEntries(
    Object.entries(comprasPorMes).sort((a, b) => compareMonths(a[0], b[0]))
  );

  return (
    <div>
      <Typography
        variant="h1"
        component="div"
        gutterBottom
        fontSize="16px"
        fontWeight="bold"
        align="left"
        color={"#1A2027"}
      >
        Compras por Mes:
      </Typography>

      <Divider
        sx={{
          backgroundColor: "#1A2027",
          mt: 2,
          mb: 2,
        }}
      />
      {isLoading ? (
        // Skeleton Loading while data is loading
        <Skeleton
          variant="rectangular"
          width="100%"
          height={150}
          animation="wave"
        />
      ) : (
        // Display the content after data is loaded
        Object.entries(sortedVentasPorMes).map(([mes, ventas]) => (
          <div key={mes}>
            <h3
              style={{
                textAlign: "left",
                marginBottom: "10px",
                cursor: "pointer",
                fontSize: "16px", // Set the text size to 16px
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => toggleMonth(mes)}
            >
              <Icon style={{ fontSize: 20, marginRight: "5px" }} />{" "}
              {/* Add the icon */}
              {mes}
            </h3>
            {expandedMonths[mes] && (
              <div>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                  <thead>
                    <tr>
                      <td colSpan={2}>
                        <Divider style={{ margin: "10px 0" }} />
                      </td>
                    </tr>
                    <tr>
                      <th>Proveedor</th>
                      <th>Monto Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ventas.map((venta, index) => (
                      <tr key={index}>
                        <td>{venta.razon_social}</td>
                        <td>{formatCurrency(venta.monto_total)}</td>{" "}
                        {/* Format the amount as currency */}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <hr style={{ margin: "10px 0" }} /> {/* Add the divider */}
              </div>
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default VentasPorMes;
