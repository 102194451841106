import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function CollapsibleExample() {
  return (
    <Navbar sticky="top"  className='navigator' collapseOnSelect expand="lg">
      <Container>
      <Navbar.Brand href="#home">
            <img
              alt=""
              src="https://indrasolutions.cl/wp-content/uploads/2022/08/Logo-1-1.png"
              className="logo-navbar"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="nav-links">

            <Nav.Link href="https://www.liquidez.cl/">INICIO</Nav.Link>
            <Nav.Link href="https://www.liquidez.cl/hazte-cliente/">HAZTE CLIENTE</Nav.Link>
            <Nav.Link href="https://www.liquidez.cl/productos/">PRODUCTOS</Nav.Link>

            {/* <NavDropdown disabled title="PAÍSES" id="collasible-nav-dropdown">
              <NavDropdown.Item to="#action/3.1">Chile</NavDropdown.Item>
              <NavDropdown.Item to="#action/3.2">
                Perú
              </NavDropdown.Item>
            </NavDropdown> */}
            <Nav.Link href="https://www.liquidez.cl/blog/">BLOG</Nav.Link>
            <Nav.Link href="https://www.liquidez.cl/contacto/">CONTÁCTANOS</Nav.Link>
          </Nav>

        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;