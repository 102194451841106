import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { useState } from "react";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import Swal from "sweetalert2";

const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 325,
  bgcolor: "background.paper",
  borderRadius: "5px",
  padding: "10px",
  zIndex: 1000,
  boxShadow: 24,
};

const UpdPassword = () => {
  const [recuperar, setRecuperar] = useState({
    login: "",
    password: "",
    new_password: "",
  });
  const [loading, setLoading] = useState(false);

  const isFormComplete = () =>
    recuperar.login !== "" &&
    recuperar.password !== "" &&
    recuperar.new_password !== "";

  const passwordUpdate = (e) => {
    setRecuperar({
      ...recuperar,
      [e.target.name]: e.target.value,
    });
  };

  async function actualizarPassword() {
    function isEmpty() {
      if (
        recuperar.login === "" ||
        recuperar.password === "" ||
        recuperar.new_password === ""
      ) {
        Swal.fire({
          toast: true,
          position: "top-end",
          text: "Los campos no puede estar vacios",
          icon: "info",
          showConfirmButton: false,
          timer: 1500,
        });
        return true;
      }
      return false;
    }
    if (isEmpty()) {
      return;
    }

    setLoading(true);
    let items = { recuperar };
    console.warn(items);
    axios
      .put(`${process.env.REACT_APP_HOST_API}liquidez/pwd/update`, {
        login: recuperar.login,
        password: recuperar.password,
        new_password: recuperar.new_password,
      })
      .then((response) => {
        console.warn(response.data.status);
        if (response.data.status === "success") {
          Swal.fire({
            toast: true,
            position: "top-end",
            text: "Contraseña actualizada",
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
          window.location.href = "/";
        }

        setLoading(false);
      })
      .catch((err) => {
        Swal.fire({
          toast: true,
          position: "top-end",
          text: "Código de verificación o correo electrónico incorrecto",
          icon: "error",
          showConfirmButton: false,
          timer: 2000,
        });
      });
  }
  return (
    <div>
      <div>
        <Box sx={style}>
          <img
            alt="img-modal"
            src="https://indrasolutions.cl/wp-content/uploads/2022/09/Group-163.webp"
            width="20%"
          />
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            textAlign="center"
            color="#143785"
            component="h1"
            variant="h5"
          >
            ¡Código enviado!
          </Typography>
          <Typography id="" sx={{ mt: 2, mb: 2 }} textAlign="center" color="">
            Hemos enviado un código de <br></br> recuperación al correo
            ingresado.
          </Typography>
          <TextField
            onChange={passwordUpdate}
            size="small"
            margin="normal"
            placeholder="Correo electrónico"
            label="Email"
            name="login"
            type={"email"}
            InputProps={{
              startAdornment: (
                <InputAdornment className="iconos-labels" position="start">
                  <MarkEmailReadOutlinedIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            onChange={passwordUpdate}
            size="small"
            margin="normal"
            placeholder="Codigo de recuperación"
            label="Codigo de recuperación"
            name="password"
            InputProps={{
              startAdornment: (
                <InputAdornment className="iconos-labels" position="start">
                  <VpnKeyIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            onChange={passwordUpdate}
            size="small"
            margin="normal"
            placeholder="Nueva contraseña"
            label="Nueva Contraseña"
            name="new_password"
            type={"password"}
            InputProps={{
              startAdornment: (
                <InputAdornment className="iconos-labels" position="start">
                  <LockOpenIcon />
                </InputAdornment>
              ),
            }}
          />
          <Button
            endIcon={<SendIcon />}
            onClick={() => actualizarPassword()}
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            Cambiar Clave
          </Button>
          <br></br>
        </Box>
      </div>
    </div>
  );
};

export default UpdPassword;
