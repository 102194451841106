import React, { useEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

function GraficaComprasVentas({ ventasPorMes, comprasPorMes }) {
  const chartRef = useRef(null);

  useEffect(() => {
    // Set themes for amCharts
    am4core.useTheme(am4themes_animated);

    // Create chart instance
    const chart = am4core.create(chartRef.current, am4charts.XYChart);

    // Define the order of months
    const monthsOrder = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    // Sort the months based on the custom order
    const sortedMonths = Object.keys(ventasPorMes).sort((a, b) => {
      return monthsOrder.indexOf(a) - monthsOrder.indexOf(b);
    });

    // Set data
    chart.data = sortedMonths.map((mes) => ({
      mes,
      ventas:
        ventasPorMes[mes]?.reduce((acc, obj) => acc + obj.monto_total, 0) || 0,
      compras:
        comprasPorMes[mes]?.reduce((acc, obj) => acc + obj.monto_total, 0) || 0,
    }));

    // Check if there is no data available
    const hasData = chart.data.some(
      (dataItem) => dataItem.ventas > 0 || dataItem.compras > 0
    );

    // Create axes and series only if there is data
    if (hasData) {
      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "mes";
      categoryAxis.renderer.grid.template.location = 0;

      const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());

      // Create series
      const ventasSeries = chart.series.push(new am4charts.LineSeries());
      ventasSeries.dataFields.valueY = "ventas";
      ventasSeries.dataFields.categoryX = "mes";
      ventasSeries.name = "Ventas";
      ventasSeries.tooltipText = "Ventas: {valueY}";
      ventasSeries.strokeWidth = 2;
      ventasSeries.stroke = am4core.color("#e94e1b"); // Set color for ventas

      const comprasSeries = chart.series.push(new am4charts.LineSeries());
      comprasSeries.dataFields.valueY = "compras";
      comprasSeries.dataFields.categoryX = "mes";
      comprasSeries.name = "Compras";
      comprasSeries.tooltipText = "Compras: {valueY}";
      comprasSeries.strokeWidth = 2;
      comprasSeries.stroke = am4core.color("#143785"); // Set color for compras

      // Add a legend
      chart.legend = new am4charts.Legend();
    } else {
      // If there is no data, show a message in the center of the chart
      const label = chart.createChild(am4core.Label);
      label.text = "No hay datos disponibles.";
      label.align = "center";
      label.verticalCenter = "middle";
      label.fontSize = 16;
    }

    // Dispose chart when the component is unmounted
    return () => {
      chart.dispose();
    };
  }, [ventasPorMes, comprasPorMes]);

  return <div ref={chartRef} style={{ width: "100%", height: "500px" }}></div>;
}

export default GraficaComprasVentas;
