import React from "react";
import Navbar from "../componentes/Navbar/Navbar";
import Footer from "../componentes/footer/Footer";
import FormRecuperarContraseña from "../componentes/Recuperarclave/FormRecuperarContraseña";

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
  return (
    <div>
      <Navbar />
      <FormRecuperarContraseña />
      <Footer />
    </div>
  );
}
