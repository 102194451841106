import React from 'react'
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';


const BackHomeButton = () => {
const theme = useTheme();
const isPhone = useMediaQuery(theme.breakpoints.down('sm'));
    

  return (
    <Button
      LinkComponent={Link}
      to="/"
      sx={{
        color: 'black',
        backgroundColor: '#E7E7E7',
        borderColor: '#E7E7E7',

        display: isPhone ? 'none' : 'inline-flex',
      }}
      size='small'
      variant="outlined"
      endIcon={<HomeIcon />}
    >
      Volver al inicio
    </Button>
  )
}

export default BackHomeButton