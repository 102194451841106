import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { Typography } from "@mui/material";
import axios from "axios";
import { DataGrid, GridToolbar, esES } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import Item from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Swal from "sweetalert2";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useMediaQuery } from "@mui/material";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
export const CarteraVigenteComponente = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const Styles = {
    variant: "h1",
    component: "div",
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "center",
  };
  const Styles2 = {
    variant: "h1",
    component: "div",
    fontSize: "16px",
    fontWeight: "bold",
    textAlign: "left",
  };

  const columns = [
    {
      field: "idOperEnCursoDet",
      headerName: "Nº Operación",
      id: "idOperEnCursoDet",
      align: "left",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "deudor",
      headerName: "Deudor",
      id: "deudor",
      align: "left",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "numDocto",
      headerName: "N° Documento",
      id: "numDocto",
      align: "left",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "moneda",
      headerName: "Moneda",
      id: "moneda",
      align: "left",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "valorDocto",
      headerName: "Valor dcto.",
      id: "valorDocto",
      align: "left",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "anticipable",
      headerName: "Anticipable",
      id: "anticipable",
      align: "left",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "vigente",
      headerName: "Vigente",
      id: "vigente",
      align: "left",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "fechaVcto",
      headerName: "Fecha vencimiento.",
      id: "fechaVcto",
      align: "left",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
    {
      field: "estado",
      headerName: "Estado",
      id: "estado",
      align: "left",
      flex: 1,
      width: isMobile ? 150 : 200,
      minWidth: isMobile ? 150 : 200,
    },
  ];

  // quitar guion y digito verificador
  const quitarGuion = (rut) => {
    let rutSinGuion = rut.replace("-", "");
    let rutSinGuionYDigito = rutSinGuion.slice(0, -1);
    return rutSinGuionYDigito;
  };

  const formatNumber = (number) => {
    const formato = new Intl.NumberFormat("de-DE", {
      style: "currency",
      currency: "CLP",
    });
    const output = formato.format(number);
    return output;
  };

  // obtener el nombre del mes, día del mes, año, hora, minutos y segundos
  let moment = require("moment");
  let now = moment().format("DD/MM/YYYY HH:mm:ss");

  const formatearFecha = (fecha) => {
    const fechaFormateada = new Date(fecha).toLocaleDateString("es-ES");

    return fechaFormateada;
  };

  const [info, setInfo] = useState([]);
  const [totalValorDcto, setTotalValorDcto] = useState(0);
  const [totalVigente, setTotalVigente] = useState(0);
  const [totalAnticipo, setTotalAnticipo] = useState(0);
  const [totalMora, setTotalMora] = useState(0);
  const [totalDocMora, setTotalDocMora] = useState(0);
  const [totalDocVigente, setTotalDocVigente] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
    axios
      .post(
        "https://api.liquidez.cl/api/cliente/CarteraVigente",
        {
          rut: quitarGuion(JSON.parse(localStorage.getItem("rut"))),
          refreshToken: "refresh_token",
        },
        {
          headers: { ApiKey: process.env.REACT_APP_API_KEY_GVE },
          accept: "application/json",
          "content-type": "application/json",
        }
      )

      .then((response) => {
        let total1 = 0,
          total2 = 0,
          total3 = 0,
          total4 = 0,
          total5 = 0,
          total6 = 0;
        const arr = response.data.map((x, i, a) => {
          total1 += x.valorDocto;
          total2 += x.vigente;
          total3 += x.anticipable;
          //sumatoria total de documentos en mora
          if (x.estado === "Moroso") {
            total4 += x.vigente;
          }
          total5 += x.estado === "Moroso" ? 1 : 0; //total de documentos en mora
          total6 += x.estado === "Vigente" ? 1 : 0; //total de documentos vigentes
          a[i]["valorDocto"] = formatNumber(x.valorDocto); //formato de moneda
          a[i]["vigente"] = formatNumber(x.vigente); //formato de moneda
          a[i]["anticipable"] = formatNumber(x.anticipable); //formato de moneda
          a[i]["difPrecio"] = formatNumber(x.difPrecio); //formato de moneda
          a[i]["fechaVcto"] = formatearFecha(x.fechaVcto); //formato de fecha

          return a[i];
        });

        setTotalValorDcto(total1);
        setTotalVigente(total2);
        setTotalAnticipo(total3);
        setTotalMora(total4);
        setTotalDocMora(total5);
        setTotalDocVigente(total6);
        setInfo(arr);
        setOpen(false);
      })
      .catch((errors) => {
        setOpen(false);
        if (errors.response.status === 400) {
          Swal.fire({
            imageUrl:
              "https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png",
            title: "Lo sentimos",
            text: "No hay documentos vigentes en cartera",
            confirmButtonText: "continuar",
            confirmButtonColor: "#E94E1B",
            showCloseButton: true,
          });
        }
      });
  }, []);

  return (
    <>
      <Backdrop
        sx={{
          color: "#DFFEFF",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        cargando información..
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="xl">
        <Grid item xs={6} md={8}>
          <Item className="box-componente-perfil">
            <Typography
              variant="h1"
              component="div"
              gutterBottom
              fontSize="16px"
              fontWeight="bold"
              align="left"
            >
              Cartera vigente factoring
              <hr></hr>
            </Typography>
            <Typography style={Styles2}>
              Cartera vigente de cliente al:<Typography>{now}</Typography>
            </Typography>
            <hr></hr>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography style={Styles}>
                Total valor dcto <p>{formatNumber(totalValorDcto)}</p>
              </Typography>
              <Divider color="grey" orientation="vertical" flexItem />
              <Typography style={Styles}>
                Total vigente <p>{formatNumber(totalVigente)}</p>
              </Typography>
              {/* <Divider color="grey" orientation="vertical" flexItem />
            <Typography variant="subtitle1" gutterBottom component="div">
              <h6>Total anticipo</h6> <p>{formatNumber(totalAnticipo)}</p>
            </Typography> */}
              <Divider color="grey" orientation="vertical" flexItem />
              <Typography style={Styles}>
                Total Mora <p>{formatNumber(totalMora)}</p>
              </Typography>
              <Divider color="grey" orientation="vertical" flexItem />
              <Typography style={Styles}>
                Documentos morosos <p>{totalDocMora}</p>
              </Typography>
              <Divider color="grey" orientation="vertical" flexItem />
              <Typography style={Styles}>
                Documentos vigente <p>{totalDocVigente}</p>
              </Typography>
            </Stack>
          </Item>
        </Grid>
      </Container>
      <Container maxWidth="xl">
        <Stack className="box-componente-perfil">
          <Typography
            variant="h1"
            component="div"
            gutterBottom
            fontSize="16px"
            fontWeight="bold"
            align="left"
          >
            Detalle
          </Typography>
          <hr></hr>
          <Box sx={{ height: 750, width: "100%" }}>
             <DataGrid
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            rows={info}
            columns={columns}
            align="center"
            getRowId={(row) =>
              row.idOperEnCursoDet +
              row.idOperEnCurso +
              row.cliente +
              row.numDocto +
              row.rutCliente +
              row.dvCliente +
              row.documento +
              row.moneda +
              row.estado +
              row.rutDeudor +
              row.dvDeudor +
              row.deudor +
              row.valorDocto +
              row.anticipable +
              row.vigente +
              row.fechaVcto
            }
            components={{
              Toolbar: GridToolbar,
            }}
           
          />
          </Box>
        </Stack>
      </Container>

      <br></br>
    </>
  );
};
