import React, { useState, useEffect } from "react";
import "./UploadCert.css";
import axios from "axios";
import { Formik } from "formik";
import { Grid, Stack, Typography } from "@mui/material";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Alert } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Divider } from "antd";
import Swal from "sweetalert2";
import { Input } from "antd";
import Container from "@mui/material/Container";

const UploadCert = () => {
  //token bearer sii
  const token = localStorage.getItem("tokenSII");

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  }));

  const styledinputs = {
    marginTop: "10px",
    marginBottom: "10px",
  };

  const RUT_EMPRESA = JSON.parse(localStorage.getItem("rut"));

  return (
    <>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={6} md={4}>
            <Stack spacing={2} className="box-componente-perfil">
              <Typography
                variant="h1"
                component="div"
                gutterBottom
                fontSize="16px"
                fontWeight="bold"
                align="left"
              >
                Subir Certificado Digital
              </Typography>
              <Divider />

              <Formik
                initialValues={{
                  file: null,
                  pwd: "",
                  empresa_rut: "",
                }}
                validate={(values) => {
                  const errors = {};
                  // las 2 contraseñas deben ser iguales
                  if (values.pwd !== values.pwd_repeat) {
                    errors.pwd_repeat = "Las contraseñas no coinciden";
                  }
                  // el nombre del certificado solo es letras y espacio
                  if (!/^[a-zA-Z ]+$/.test(values.nombre_certificado)) {
                    errors.nombre_certificado = "Solo letras y espacios";
                  }

                  return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  Swal.fire({
                    title: "Subiendo certificado",
                    text: "Espere un momento",
                    icon: "info",
                    allowOutsideClick: false,
                    showConfirmButton: false,
                  });
                  Swal.showLoading();
                  const formData = new FormData();
                  formData.append("pwd", values.pwd);
                  formData.append("empresa_rut", RUT_EMPRESA);
                  formData.append("file", values.file);
                  axios
                    .post(
                      "https://api-sii.webfactura.cl/api/sii/certUpload",
                      formData,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                          Authorization: `Bearer ${token}`,
                        },
                      }
                    )
                    .then((response) => {
                      //tipo de archivo no valido
                      if (response.data.status === "ERROR") {
                        Swal.fire({
                          text: "El tipo de archivo no es valido.",
                          imageUrl:
                            "https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png",
                          showCancelButton: false,
                          confirmButtonColor: "#143785",
                          confirmButtonText: "Aceptar",
                        });
                      }
                      //clave incorrecta
                      else if (response.data.status === "ERR") {
                        Swal.fire({
                          imageUrl:
                            "https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png",
                          title: "Ocurrio un error",
                          text: "No se pudo leer el certificado. Revise la clave!",
                          showCancelButton: false,
                          confirmButtonColor: "#143785",
                          confirmButtonText: "Aceptar",
                        });
                      }
                      //certificado cargado correctamente
                      else if (response.data.status === "OK") {
                        Swal.fire({
                          text: "Certificado cargado correctamente",
                          imageUrl:
                            "https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png",
                          showCancelButton: false,
                          confirmButtonColor: "#143785",
                          confirmButtonText: "Aceptar",
                        });
                        resetForm();
                      }
                    })
                    .catch((error) => {
                      Swal.fire({
                        text: "Ha ocurrido un error, por favor intente nuevamente",
                        imageUrl:
                          "https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png",
                        showCancelButton: false,
                        confirmButtonColor: "#143785",
                        confirmButtonText: "Aceptar",
                      });
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Stack justifyContent="space-evenly" alignItems="center">
                      <TextField
                        style={styledinputs}
                        fullWidth
                        required
                        name="nombre_certificado"
                        label="ingrese nombre y apellido del certificado"
                        variant="outlined"
                        size="small"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        touched={touched.nombre_certificado}
                        error={Boolean(errors.nombre_certificado)}
                        helperText={errors.nombre_certificado}
                      />
                      <TextField
                        style={styledinputs}
                        required
                        name="empresa_rut"
                        label="Rut del certificado"
                        variant="outlined"
                        size="small"
                        fullWidth
                        disabled
                        onChange={handleChange}
                        value={RUT_EMPRESA}
                      />
                      <TextField
                        style={styledinputs}
                        required
                        name="pwd"
                        label="contraseña del certificado"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={handleChange}
                        value={values.pwd}
                        touched={touched.pwd}
                        error={Boolean(errors.pwd)}
                        helperText={errors.pwd}
                        onBlur={handleBlur}
                      />
                      <TextField
                        style={styledinputs}
                        required
                        name="pwd_repeat"
                        label="Repetir contraseña"
                        variant="outlined"
                        size="small"
                        fullWidth
                        onChange={handleChange}
                        touched={touched.pwd_repeat}
                        error={Boolean(errors.pwd_repeat)}
                        helperText={errors.pwd_repeat}
                        onBlur={handleBlur}
                      />
                      <Typography
                        variant="h6"
                        component="div"
                        gutterBottom
                        fontSize={15}
                        textAlign="left"
                      >
                        Subir certificado (PFX)
                      </Typography>
                      <Input
                        accept=".pfx"
                        required
                        type="file"
                        name="file"
                        onChange={(event) => {
                          handleChange(event);
                          setFieldValue("file", event.currentTarget.files[0]);
                        }}
                      />
                      <LoadingButton
                        style={styledinputs}
                        className="btn-send-cert"
                        fullWidth
                        type="submit"
                        variant="contained"
                        size="small"
                      >
                        Enviar certificado
                      </LoadingButton>
                    </Stack>
                  </form>
                )}
              </Formik>
            </Stack>
          </Grid>
          <Grid item xs={6} md={8}>
            <Stack className="box-componente-perfil">
              <Typography
                variant="h1"
                component="div"
                gutterBottom
                fontSize="16px"
                fontWeight="bold"
                align="left"
              >
                ¿Por qué enviar esta información?
                <hr></hr>
              </Typography>
              <Alert
                sx={{ mb: 2 }}
                fontSize="16px"
                severity="info"
                align="left"
                style={{
                  fontWeight: "500",
                }}
              >
                Este paso es necesario para validar que cuentas con los permisos
                necesarios para ceder facturas de la empresa.
              </Alert>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default UploadCert;
