import React from "react";
import { Typography, Divider, Box } from "@mui/material";
import { formatCurrency } from "./utils"; // Importa la función formatCurrency desde utils

const FacturasCesionadas = ({ facturasVentasCecionadas, isLoading }) => {
  console.log("facturas cesionadas", facturasVentasCecionadas);

  if (isLoading) {
    return <Box>{/* Código de carga o esqueleto */}</Box>;
  }

  // Lógica y contenido del componente cuando no está cargando

  return (
    <Box>
      <Typography
        variant="h1"
        component="div"
        gutterBottom
        fontSize="16px"
        fontWeight="bold"
        align="left"
        color={"#1A2027"}
      >
        Facturas Cesionadas:
      </Typography>{" "}
      <ul>
        {Object.entries(facturasVentasCecionadas).map(
          ([key, invoices], index) => (
            <div key={index}>
              <Typography variant="h6">{key}</Typography>
              <ul>
                {invoices.map((factura, subIndex) => (
                  <li key={subIndex}>
                    <Typography>
                      Fecha de Emisión: {factura.fecha_emision}
                    </Typography>
                    <Typography>
                      Razón Social: {factura.razon_social}
                    </Typography>
                    <Typography>RUT Cliente: {factura.rut_cliente}</Typography>
                    <Typography>Folio: {factura.folio}</Typography>
                    <Typography>
                      Monto Total: {formatCurrency(factura.monto_total)}{" "}
                      {/* Formatea el monto con formatCurrency */}
                    </Typography>
                    <Typography>
                      Cedida: {factura.cedida ? "Sí" : "No"}
                    </Typography>
                    {factura.cedida && (
                      <>
                        <Typography>
                          Razón Social del Cesionario: {factura.rz_cesionario}
                        </Typography>
                        <Typography>
                          Fecha del Cesionario: {factura.fecha_cesionario}
                        </Typography>
                        <Typography>
                          Monto de Cesión:{" "}
                          {formatCurrency(factura.monto_cesion)}{" "}
                          {/* Formatea el monto con formatCurrency */}
                        </Typography>
                        <Typography>Cedente: {factura.cedente}</Typography>
                        <Typography>
                          Cesionario: {factura.cesionario}
                        </Typography>
                      </>
                    )}
                    <Divider />
                  </li>
                ))}
              </ul>
            </div>
          )
        )}
      </ul>
    </Box>
  );
};

export default FacturasCesionadas;
