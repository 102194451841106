import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import "./FormularioCesion.css";
import { Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Swal from "sweetalert2";
import { Formik } from "formik";
import { FormControl, InputLabel, Select } from "@mui/material";
import axios from "axios";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import { opcionesCuenta, opcionesBanco } from "./opciones";
import { Typography } from "antd";
import sendEmailCliente from "../mails/SendEmailCliente"; 
import SendEmailLiquidez from "../mails/SendEmailLiquidez";


const FormularioCesion = ({ buttonParams, open, handleFormClose }) => {
  const RUT_LIQUIDEZ = "76882240-9";
  const RAZON_SOCIAL_LIQUIDEZ = "LIQUIDEZ CAPITAL CHILE";
  const [open3, setOpen3] = useState(false);
  

  const [cuenta, setCuenta] = useState(opcionesCuenta);
  const [banco, setBanco] = useState(opcionesBanco);

  //estilos para los inputs disabled
  const styleOne = {
    backgroundColor: "#F9F9F9",
  };

  const quitarGuion = (rut) => {
    let rutSinGuion = rut.replace("-", "");
    let rutSinGuionYDigito = rutSinGuion.slice(0, -1);
    return rutSinGuionYDigito;
  };

  const [informacion, setInformacion] = useState({
    NombreRepresentanteLegal: "",
    rsocial: "",
  });

  useEffect(() => {
    axios
      .post(
        "https://api.liquidez.cl/api/cliente/FichaClientes",
        {
          rut: quitarGuion(JSON.parse(localStorage.getItem("rut"))),
        },
        {
          headers: { ApiKey: process.env.REACT_APP_API_KEY_GVE },
        }
      )
      .then((response) => {
        const { NombreRepresentanteLegal, rsocial } = response.data[0];
        // Asignar valores al estado
        setInformacion({
          NombreRepresentanteLegal,
          rsocial,
        });
      })
      .catch((error) => {});
  }, []);

  const DECLARACION = `Yo, ${
    informacion.NombreRepresentanteLegal
  }, representando a ${informacion.rsocial}, RUT ${localStorage.getItem(
    "rut"
  )}, declaro que he puesto a disposicion del cesionario ${RAZON_SOCIAL_LIQUIDEZ} RUT ${RUT_LIQUIDEZ}, el documento donde constan los recibos de la recepción de mercaderías entregadas o servicios prestados, entregados por parte del deudor de la factura ${
    buttonParams?.row?.razon_social
  }, RUT ${
    buttonParams?.row?.rut_cliente
  }, de acuerdo a lo establecido en la Ley N° 19.983`;

  //datos para la empresa que va a recibir la cesion

  const [fileInput, setFileInput] = useState(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileBase64, setFileBase64] = useState("");
  const [open2, setOpen2] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64WithPrefix = reader.result;
      const base64 = base64WithPrefix.split(",")[1];

      // Muestra el mensaje de éxito al cargar el archivo
      setFileUploaded(true);
      setFileName(file.name);
      setFileBase64(base64); // Agregar esta línea para actualizar el estado del archivo
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };


  //mail para el cliente
  const cederFactura = (values) => {
    axios
      .post(
        "https://indra-liquidez.vercel.app/liquidez/facturas/electronica/ceder-factura",
        {
          email: values.email,
          declaracion_jurada: DECLARACION,
          xml: values.file,
        },
        {
          headers: {
            Authorization: JSON.parse(localStorage.getItem("token")),
          },
        }
      )
      .then((response) => {
        const { data } = response;

        if (
          data.code === 400 ||
          (data.code === 200 && data.status === "fail")
        ) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data.message,
          });
          setOpen3(false);
        } else {
          Swal.fire({
            icon: "success",
            title: "Cesión en progreso",
            text:
              "La cesión de la factura n° " +
              values.folio +
              " se encuentra en progreso, en breve recibirá un correo con los detalles, la cesión se puede demorar unos minutos en ser reflejada en su cuenta de SII.",
          });
          setOpen2(false);
          sendEmailCliente(values);
          SendEmailLiquidez(values);
          handleFormClose();
        }
        setOpen3(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /* const generarXML = (values) => {
    axios({
      method: 'GET',
      url: 'plugin/DTE_DOWN77091573-2.xml',
      responseType: 'blob'
    })
    .then((response) => {
      const reader = new FileReader();
      reader.readAsDataURL(response.data);
      reader.onloadend = function() {
        const base64data = reader.result;
        values.file = base64data;
        cederFactura(values);
      }
    })
    .catch((err) => {
    });
  }; */

  return (
    <>
      <Dialog
        open={open}
        onClose={handleFormClose}
        aria-labelledby="form-dialog-title"
      >
        <img
          src="https://indrasolutions.cl/wp-content/uploads/2022/08/logo-azul.png"
          alt="logo"
          style={{
            marginTop: "20px",
            width: "250px",

            display: "block",
            marginLeft: "auto",

            marginRight: "auto",
          }}
        ></img>
        <DialogTitle
          sx={{
            textAlign: "center",
          }}
        >
          Proceso de cesión de la factura N°
          {buttonParams?.row?.folio}
        </DialogTitle>

        <DialogContent>
          <DialogContentText></DialogContentText>
          <Alert severity="info">
            Para el correcto proceso de cesión de la factura debe completar los
            siguientes datos:
          </Alert>
          <InputLabel
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
              marginTop: "10px",
              color: "black",
            }}
          >
            Datos de la factura
          </InputLabel>
          <Formik
            initialValues={{
              file: fileBase64,
              nombre: "",
              rSocial: buttonParams?.row?.razon_social,
              banco: "",
              tipoCuenta: "",
              nCuenta: "",
              email: "",
              folio: buttonParams?.row?.folio,
              tipoVenta: buttonParams?.row?.tipo_venta,
              rut: "",
              fechaEmision: buttonParams?.row?.fecha_emision,
            }}
            validate={(values) => {
              const errores = {};
              //validacion nombre y apellido
              if (!values.nombre) {
                errores.nombre = "Campo obligatorio";
              } else if (!/^[a-zA-ZÀ-ÿ\s]{1,40}$/.test(values.nombre)) {
                errores.nombre = "Solo letras y espacios";
              }

              //validacion correo
              if (!values.email) {
                errores.email = "Campo obligatorio";
              } else if (
                !/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(
                  values.email
                )
              ) {
                errores.email = "Por favor ingrese un email válido";
              }
              //validacion numero de cuenta
              if (!values.nCuenta) {
                errores.nCuenta = "Campo obligatorio";
              } else if (!/^\d+$/.test(values.nCuenta)) {
                errores.nCuenta = "Solo se permiten números";
              }

              return errores;
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              setTimeout(() => {
                handleFormClose();
                values.file = fileBase64;
                Swal.fire({
                  title:
                    "¿Está seguro de cesionar la factura? N°" + values.folio,
                  text: "No podrá revertir esta acción",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Sí, cesionar",
                  cancelButtonText: "Cancelar",
                }).then((result) => {
                  if (result.isConfirmed) {
                    setOpen3(true);
                    cederFactura(values);
                    resetForm();
                  }
                });
                setSubmitting(false);
              }, 400);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginTop: "10px",
                    gap: "10px",
                  }}
                >
                  <TextField
                    style={styleOne}
                    fullWidth
                    name="rSocial"
                    type="text"
                    placeholder={buttonParams?.row?.razon_social}
                    value={buttonParams?.row?.razon_social}
                    disabled
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></TextField>
                  <TextField
                    style={styleOne}
                    name="rut_cliente"
                    fullWidth
                    type="text"
                    placeholder={buttonParams?.row?.rut_cliente}
                    value={buttonParams?.row?.rut_cliente}
                    disabled
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></TextField>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  <TextField
                    style={styleOne}
                    fullWidth
                    name="folio"
                    type="text"
                    placeholder={buttonParams?.row?.folio}
                    value={buttonParams?.row?.folio}
                    disabled
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></TextField>
                  <TextField
                    style={styleOne}
                    fullWidth
                    name="fechaEmision"
                    type="text"
                    placeholder={buttonParams?.row?.fecha_emision}
                    label={buttonParams?.row?.fecha_emision}
                    disabled
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></TextField>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: "10px",
                    gap: "10px",
                  }}
                ></div>
                <Typography
                  style={{
                    marginBottom: "10px",
                    marginTop: "10px",
                    color: "black",
                    fontFamily: "roboto",
                    fontSize: "1rem",
                  }}
                >
                  Datos Bancarios
                </Typography>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    name="nCuenta"
                    type="text"
                    placeholder="numero de cuenta"
                    label="N° de cuenta"
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.nCuenta && touched.nCuenta}
                    helperText={
                      errors.nCuenta && touched.nCuenta ? errors.nCuenta : ""
                    }
                  ></TextField>
                  <TextField
                    required
                    fullWidth
                    name="email"
                    type="text"
                    placeholder="email"
                    label="Correo electrónico"
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email}
                    helperText={
                      errors.email && touched.email ? errors.email : ""
                    }
                  ></TextField>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                    marginTop: "10px",
                    gap: "10px",
                  }}
                >
                  <div>
                    <FormControl>
                      <Select
                        required
                        placeholder="Banco"
                        name="banco"
                        value={values.banco}
                        onChange={handleChange}
                        size="small"
                        native
                      >
                        <option value="" disabled selected>
                          Seleccione un Banco
                        </option>
                        {banco.map((item) => (
                          <option key={item.id} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div>
                    <FormControl>
                      <Select
                        required
                        name="tipoCuenta"
                        value={values.tipoCuenta}
                        onChange={handleChange}
                        size="small"
                        native
                      >
                        <option value="" disabled selected>
                          Seleccione un tipo de cuenta
                        </option>
                        {cuenta.map((item) => (
                          <option key={item.id} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  <TextField
                    required
                    fullWidth
                    name="nombre"
                    value={values.nombre}
                    type="text"
                    label="Nombre y apellido"
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.nombre}
                    error={errors.nombre && touched.nombre}
                    helperText={
                      errors.nombre && touched.nombre ? errors.nombre : ""
                    }
                  ></TextField>
                  <TextField
                    required
                    name="rut"
                    label="Rut"
                    fullWidth
                    type="text"
                    placeholder="Ingrese su rut"
                    value={values.rut}
                    size="small"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.rut && touched.rut}
                    helperText={errors.rut && touched.rut ? errors.rut : ""}
                  ></TextField>
                </div>
                <div>
                  <input
                    id="fileInput"
                    type="file"
                    accept=".xml"
                    style={{ display: "none" }}
                    ref={(input) => setFileInput(input)}
                    onChange={handleFileChange}
                  />
                  <InputLabel
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    Documento XML de la factura
                  </InputLabel>
                  <Button
                    variant="outlined"
                    onClick={() => fileInput.click()}
                    startIcon={<CloudUploadIcon />}
                  >
                    Subir Xml
                  </Button>
                  {fileUploaded && (
                    <div>
                      <p style={{ color: "green" }}>
                        El archivo se subió correctamente.
                      </p>
                      <p>Nombre del archivo: {fileName}</p>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "10px",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isSubmitting}
                    sx={{ width: "100%" }}
                  >
                    Ceder factura
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFormClose} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{
          color: "#DFFEFF",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open2}
      >
        Enviando Correo...
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        sx={{
          color: "#DFFEFF",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={open3}
      >
        cargando información...
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default FormularioCesion;
